import phone from "assets/images/user-phone.svg";
import mail from "assets/images/user-mail.svg";
import Location from "assets/images/location.svg";
import clock from "assets/images/clock.svg";
import lang from "assets/images/user-lang.svg";

import orderIcon from "assets/images/order-white-icon.svg";
import sessionDarkIcon from "assets/images/session-dark-icon.svg";
import orderDarkIcon from "assets/images/order-dark-icon.svg";
import sessionIcon from "assets/images/session-white-icon.svg";
import dietary from "assets/images/dietary-white-icon.svg";

import {
  Container,
  CardContainer,
  TabeContainer,
  TabeCart,
  StatusTabeContainer,
} from "./styled";
import { dispatch } from "store";
import {
  setUserActiveButton,
  setUserStatusActiveButton,
} from "store/user-slice";
import { useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";

import Pagination from "components/particles/pagination/pagination";
import Button from "components/particles/primary-button";
import NoDataFound from "components/particles/no-data-found";
import { siteRoutes } from "utils/helpers/enums/routes.enums";
import { tableData } from "utils/helpers/dummyData";

import { useLocation, useNavigate } from "react-router-dom";
import view from "assets/images/view-icon.svg";
import DietaryChoisePopup from "components/popus/dietary-choise";
import rating from "assets/images/RatingStar.svg";
import StatusTabe from "components/particles/status-tabe";

import { Status } from "containers/home-chefs/view/styled";
import { useForm } from "react-hook-form";
import {
  reset,
  UsersViewOrdersSearchDTO,
  UsersViewSessionSearchDTO,
} from "utils/helpers/models/users.dto";
import useUserDetails from "./useHook";

export default function View() {
  const [data, setData] = useState<any>([]);
  const [ordersPaginationState, setOrdersPaginationState] = useState<any>();
  const [sessionsPaginationState, setSessionsPaginationState] = useState<any>();
  const [orderData, setOrderData] = useState<any>([]);
  const [sessionData, setSessionData] = useState<any>([]);
  const [modalData, setModalData] = useState<any>(null);
  const [buttonClicked, setButtonClicked] = useState<boolean>(true);
  const location = useLocation();
  const [id, setId] = useState<any>(0);
  const { state } = location;
  const { getUserViewById, getOrderByUserById, getSessionByUserById } =
    useUserDetails();

  console.log(ordersPaginationState, "ordersPaginationState");
  console.log(sessionsPaginationState, "setSessionsPaginationState");

  const handleDishViewPage = (userId: string, dishId: number) => {
    navigate(siteRoutes.usersViewDetail, { state: { dishId, userId } });
  };

  const handleOrderViewPage = (userId: string, orderId: number) => {
    navigate(siteRoutes.usersViewPrint, { state: { orderId, userId } });
  };

  useEffect(() => {
    if (state?.id) {
      getUserViewById(state.id, setData);
    }
  }, [state?.id]);

  useEffect(() => {
    if (state?.id) {
      const status = state?.status;
      const orderId = state?.orderId;
      const createdAt = state?.createdAt;
      const retailerName = state?.retailerName;

      getOrderByUserById(
        state.id,
        status,
        orderId,
        createdAt,
        retailerName,
        setOrderData,
        setOrdersPaginationState
      );
    }
  }, [state?.id]);

  useEffect(() => {
    if (state?.id) {
      const status = state?.status;
      const sessionId = state?.sessionId;
      const dishName = state?.dishName;
      const createdAt = state?.createdAt;
      const homeCookName = state?.homeCookName;

      getSessionByUserById(
        state.id,
        status,
        setSessionData,
        sessionId,
        dishName,
        createdAt,
        homeCookName,
        setSessionsPaginationState
      );
    }
  }, [state?.id]);

  function onStatusChange(type: string, id: string, status: string) {
    if (type === "Session") {
      getSessionByUserById(
        state.id,
        status,
        setSessionData,
        "",
        "",
        "",
        "",
        setSessionsPaginationState
      );
    } else if (type === "Order") {
      getOrderByUserById(
        state.id,
        status,
        "",
        "",
        "",
        setOrderData,
        setOrdersPaginationState
      );
    }
  }

  const { userActiveButton, userStatusActiveButton, isLoading } = useSelector(
    (state: any) => state.user
  );
  const [modalIsOpen, setIsOpen] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset: formReset,
  } = useForm<UsersViewOrdersSearchDTO | UsersViewSessionSearchDTO | reset>();

  const navigate = useNavigate();
  const search = (
    dataa: UsersViewOrdersSearchDTO | UsersViewSessionSearchDTO
  ) => {
    const statusFilter =
      userStatusActiveButton !== "All" ? userStatusActiveButton : dataa.status;

    if (userActiveButton === "Session Details" && "name" in dataa) {
      getSessionByUserById(
        state.id,
        statusFilter || undefined,
        setSessionData,
        dataa.id,
        dataa.name,
        dataa.date,
        dataa.cook,
        setSessionsPaginationState
      );
    } else if (userActiveButton === "Order Details") {
      getOrderByUserById(
        state.id,
        statusFilter || undefined,
        dataa.id,
        dataa.date,
        dataa.retailer,
        setOrderData,
        setOrdersPaginationState
      );
    }
  };
  const reset = () => {
    formReset({
      name: "",
      date: "",
      cook: "",
      retailer: "",
      status: "",
    });
    if (userActiveButton === "Session Details") {
      getSessionByUserById(
        state.id,
        "",
        setSessionData,
        "",
        "",
        "",
        "",
        setSessionsPaginationState
      );
    } else if (userActiveButton === "Order Details") {
      getOrderByUserById(
        state.id,
        "",
        "",
        "",
        "",
        setOrderData,
        setOrdersPaginationState
      );
    }
  };

  const limit = 10;
  function fetchNextRecords(offset: number) {
    // if (userActiveButton === "Session Details") {
    //   getSessionByUserById(
    //     state.id,
    //     userStatusActiveButton ! == "All" ? userStatusActiveButton : "",
    //     setSessionData,
    //     "",
    //     "",
    //     "",
    //     "",
    //     setSessionsPaginationState,
    //     limit,
    //     offset
    //   );
    // } else if (userActiveButton === "Order Details") {
    //   getOrderByUserById(
    //     state.id,
    //     userStatusActiveButton,
    //     "",
    //     "",
    //     "",
    //     setOrderData,
    //     setOrdersPaginationState,
    //     limit,
    //     offset
    //   );
    // }
    console.log(offset);
  }

  function dietaryModalOpen(dietaryData: any) {
    setModalData(dietaryData);
    setIsOpen(true);
  }

  return (
    <Container>
      <div className="title">
        <h1>User Profile</h1>
      </div>
      {data?.length > 0 ? (
        data.map((item: any, index: number) => (
          <CardContainer>
            <img src={item?.image} alt="img" className="profile-img" />
            <div className="profile">
              <h1>{item?.name}</h1>
              <p>{item?.bio}</p>
            </div>
            <div className="user-info">
              <div className="info-row">
                <img src={mail} alt="mail" />
                <p>{item?.email}</p>
              </div>
              <div className="info-row">
                <img src={phone} alt="phone" />
                <p>{item?.mobileNumber}</p>
              </div>
              <div className="info-row">
                <img src={clock} alt="weightScale" />
                <p>{item?.dob ? item.dob.split("T")[0] : "N/A"}</p>
              </div>
              <div className="info-row">
                <img src={lang} alt="height" />
                <p>{item.language.map((item: any) => item)}</p>
              </div>
              <div className="info-row">
                <img src={Location} alt="calendar" />
                <p>{item?.address}</p>
              </div>
            </div>
            <div onClick={() => dietaryModalOpen(item?.dietaryPreferences)}>
              <button>
                <div className="dietary-icon">
                  <img src={dietary} alt="" />
                </div>
                <h3>Dietary Choices</h3>
              </button>
            </div>
          </CardContainer>
        ))
      ) : (
        <p>Loading user data...</p>
      )}
      <TabeContainer>
        <TabeCart
          active={userActiveButton === "Order Details"}
          onClick={() => {
            dispatch(setUserActiveButton("Order Details"));
          }}
        >
          <div className="categories-tab-img">
            <img
              src={
                userActiveButton === "Order Details" ? orderIcon : orderDarkIcon
              }
              alt="img"
            />
          </div>
          <h1>Order Details</h1>
        </TabeCart>
        <TabeCart
          active={userActiveButton === "Session Details"}
          onClick={() => {
            dispatch(setUserActiveButton("Session Details"));
          }}
        >
          <div className="categories-tab-img">
            <img
              src={
                userActiveButton === "Session Details"
                  ? sessionIcon
                  : sessionDarkIcon
              }
              alt="img"
            />
          </div>
          <h1>Session Details</h1>
        </TabeCart>
      </TabeContainer>
      <StatusTabeContainer>
        {userActiveButton === "Session Details" ? (
          <>
            <StatusTabe
              active={userStatusActiveButton === "All"}
              onclick={() => {
                onStatusChange("Session", state.id, "");
                dispatch(setUserStatusActiveButton("All"));
              }}
              title={"All"}
            />
            <StatusTabe
              active={userStatusActiveButton === "active"}
              onclick={() => {
                onStatusChange("Session", state.id, "active");
                dispatch(setUserStatusActiveButton("active"));
              }}
              title={"Active"}
            />
            <StatusTabe
              active={userStatusActiveButton === "completed"}
              onclick={() => {
                onStatusChange("Session", state.id, "completed");
                dispatch(setUserStatusActiveButton("completed"));
              }}
              title={"Completed"}
            />
            <StatusTabe
              active={userStatusActiveButton === "incoming"}
              onclick={() => {
                onStatusChange("Session", state.id, "incoming");
                dispatch(setUserStatusActiveButton("incoming"));
              }}
              title={"Incoming"}
            />
            <StatusTabe
              active={userStatusActiveButton === "rejected"}
              onclick={() => {
                onStatusChange("Session", state.id, "rejected");
                dispatch(setUserStatusActiveButton("rejected"));
              }}
              title={"Rejected"}
            />
            <StatusTabe
              active={userStatusActiveButton === "pending"}
              onclick={() => {
                onStatusChange("Session", state.id, "pending");
                dispatch(setUserStatusActiveButton("pending"));
              }}
              title={"Pending"}
            />
            <StatusTabe
              active={userStatusActiveButton === "expired"}
              onclick={() => {
                onStatusChange("Session", state.id, "expired");
                dispatch(setUserStatusActiveButton("expired"));
              }}
              title={"Expired"}
            />
            <StatusTabe
              active={userStatusActiveButton === "readyToPay"}
              onclick={() => {
                onStatusChange("Session", state.id, "readyToPay");
                dispatch(setUserStatusActiveButton("readyToPay"));
              }}
              title={"Ready to Pay"}
            />
            <StatusTabe
              active={userStatusActiveButton === "paid"}
              onclick={() => {
                onStatusChange("Session", state.id, "paid");
                dispatch(setUserStatusActiveButton("paid"));
              }}
              title={"Paid"}
            />
          </>
        ) : (
          <>
            <StatusTabe
              active={userStatusActiveButton === "All"}
              onclick={() => {
                onStatusChange("Order", state.id, "");
                dispatch(setUserStatusActiveButton("All"));
              }}
              title={"All"}
            />
            <StatusTabe
              active={userStatusActiveButton === "Active"}
              onclick={() => {
                onStatusChange("Order", state.id, "Active");
                dispatch(setUserStatusActiveButton("Active"));
              }}
              title={"Active"}
            />
            {/* <StatusTabe
              active={userStatusActiveButton === "BaggedUp"}
              onclick={() => {
                onStatusChange("Order", state.id, "BaggedUp");
                dispatch(setUserStatusActiveButton("BaggedUp"));
              }}
              title={"Bagged Up"}
            /> */}
            <StatusTabe
              active={userStatusActiveButton === "Completed"}
              onclick={() => {
                onStatusChange("Order", state.id, "Completed");
                dispatch(setUserStatusActiveButton("Completed"));
              }}
              title={"Completed"}
            />
            <StatusTabe
              active={userStatusActiveButton === "Rejected"}
              onclick={() => {
                onStatusChange("Order", state.id, "Rejected");
                dispatch(setUserStatusActiveButton("Rejected"));
              }}
              title={"Rejected"}
            />
            {/* <StatusTabe
              active={userStatusActiveButton === "Cancelled"}
              onclick={() => {
                onStatusChange("Order", state.id, "Cancelled");
                dispatch(setUserStatusActiveButton("Cancelled"));
              }}
              title={"Cancelled"}
            /> */}
            <StatusTabe
              active={userStatusActiveButton === "Expired"}
              onclick={() => {
                onStatusChange("Order", state.id, "Expired");
                dispatch(setUserStatusActiveButton("Expired"));
              }}
              title={"Expired"}
            />
            <StatusTabe
              active={userStatusActiveButton === "Pending"}
              onclick={() => {
                onStatusChange("Order", state.id, "Pending");
                dispatch(setUserStatusActiveButton("Pending"));
              }}
              title={"Pending"}
            />
            {/* <StatusTabe
              active={userStatusActiveButton === "Incoming"}
              onclick={() => {
                onStatusChange("Order", state.id, "Incoming");
                dispatch(setUserStatusActiveButton("Incoming"));
              }}
              title={"Incoming"}
            />
            <StatusTabe
              active={userStatusActiveButton === "Readytopick"}
              onclick={() => {
                onStatusChange("Order", state.id, "Readytopick");
                dispatch(setUserStatusActiveButton("Readytopick"));
              }}
              title={"Ready to Pick"}
            /> */}
          </>
        )}
      </StatusTabeContainer>

      <div className="search-container">
        <div className="input-fields">
          {userActiveButton === "Order Details" ? (
            <>
              <input
                type="text"
                placeholder="Search By Order id"
                {...register("id")}
              />
              {/* <input type="date" name="date" id="date" /> */}
              <div className="custom-date-input-container">
                <input
                  type="date"
                  className="custom-date-input"
                  placeholder="Search by Date"
                  {...register("date")}
                />
              </div>
              <input
                type="text"
                placeholder="Search By Retailer"
                {...register("retailer")}
              />
            </>
          ) : userActiveButton === "Session Details" ? (
            <>
              <input
                type="text"
                placeholder="Search By Session Id"
                {...register("id")}
              />
              <input
                type="text"
                placeholder="Search By Dishes Name"
                {...register("name")}
              />
              {/* <input type="date" name="date" id="date" /> */}
              <div className="custom-date-input-container">
                <input
                  type="date"
                  className="custom-date-input"
                  placeholder="Search by Date"
                  {...register("date")}
                />
              </div>
              <input
                type="text"
                placeholder="Search By Home Cook"
                {...register("cook")}
              />
            </>
          ) : (
            ""
          )}
        </div>
        <div className="button-fields">
          <Button
            title="search"
            width="16rem"
            fill="true"
            onclick={handleSubmit(search)}
          />
          <Button
            title="Reset"
            width="16rem"
            fill="true"
            backgroundcolor="var(--reset-button)"
            onclick={handleSubmit(reset)}
          />
        </div>
      </div>
      <div className="data-table">
        <table>
          <thead>
            <tr>
              <th className="th">
                {userActiveButton === "Order Details"
                  ? "Order Id"
                  : "Session Id"}
              </th>
              {userActiveButton === "Order Details" ? (
                <th className="th">Items</th>
              ) : (
                <th>Dish Name</th>
              )}
              {userActiveButton === "Order Details" ? null : (
                <th className="th">Dish Image</th>
              )}
              <th>Date</th>
              <th className="th">Amount</th>
              <th>
                {userActiveButton === "Order Details"
                  ? "Retailer Name"
                  : "Home Cook"}
              </th>
              {userActiveButton === "Order Details" ? null : (
                <th className="th">Duration</th>
              )}
              {/* {userActiveButton === "Order Details" ? null : (
                <th className="th">Rating</th>
              )} */}
              {userStatusActiveButton === "All" ? (
                <th className="th">Status</th>
              ) : null}
              <th className="th">Actions</th>
            </tr>
          </thead>
          <tbody>
            {userActiveButton === "Order Details" ? (
              orderData?.data?.length > 0 ? (
                orderData.data.map((orders: any, index: number) => (
                  <tr key={index}>
                    <td className="th">{orders.generatedOrderId}</td>
                    <td className="th">{orderData.totalCartItems}</td>
                    <td>{orders.createdAt.split("T")[0]}</td>
                    <td className="th">{orders.totalAmount.toFixed(2)}</td>
                    <td>
                      {orders.retailerInfo.firstName}
                      {orders.retailerInfo.lastName}
                    </td>
                    <td>{orders.status}</td>

                    <td className="actions th">
                      <div
                        className="view-container"
                        onClick={() =>
                          handleOrderViewPage(state.id, orders.generatedOrderId)
                        }
                      >
                        <img src={view} alt="img" />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <NoDataFound isShow={!isLoading} />
              )
            ) : sessionData?.length > 0 ? (
              sessionData.map((item: any, index: number) => (
                <tr key={index}>
                  <td className="th">{item.sessionId}</td>
                  <td>{item.dish.dishName}</td>
                  <td>
                    <img
                      className="img"
                      src={item.dish.coverImage[0]}
                      alt="img"
                    />
                  </td>
                  <td>{item.sessionDetails.placedOn.split("T")[0]}</td>
                  <td className="th">{item.sessionDetails.sessionAmount}</td>
                  <td>{item.homeCook.homeCookName}</td>
                  <td className="th">
                    {(() => {
                      const [hours, minutes] =
                        item.sessionDetails.sessionDuration.split(":");
                      const formattedHours = parseInt(hours, 10);
                      const formattedMinutes = parseInt(minutes, 10);
                      return `${formattedHours} hour${
                        formattedHours !== 1 ? "s" : ""
                      }${
                        formattedMinutes > 0
                          ? `, ${formattedMinutes} minute${
                              formattedMinutes !== 1 ? "s" : ""
                            }`
                          : ""
                      }`;
                    })()}
                  </td>

                  {/* <td className="th">
                    <img src={rating} alt="img" /> {item.rating}
                  </td> */}
                  {userStatusActiveButton === "All" && (
                    <td className="th">
                      <Status active={item.status}>
                        {item.sessionDetails.sessionStatus}
                      </Status>
                    </td>
                  )}
                  <td className="actions th">
                    <div
                      className="view-container"
                      onClick={() =>
                        handleDishViewPage(state.id, item.dish.dishId)
                      }
                    >
                      <img src={view} alt="img" />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <NoDataFound isShow={!isLoading} />
            )}
          </tbody>
        </table>
        {/* )} */}
      </div>
      <DietaryChoisePopup
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        modalData={modalData}
      />
      <Fragment>
        <Pagination
          totalRecords={
            userActiveButton === "Order Details"
              ? ordersPaginationState?.totalItems
              : sessionsPaginationState?.totalSessions
          }
          perPage={limit}
          handleFetchNextRecords={fetchNextRecords}
        />
      </Fragment>
    </Container>
  );
}
