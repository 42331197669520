import calender from "assets/images/Wallet.svg";
import time from "assets/images/time.svg";
import { ViewDetail, ReceipeDetail } from "./style";
import receipe from "assets/images/receipe.svg";
import Button from "components/particles/primary-button";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecepieDetails } from "./useHook";
import { useSelector } from "react-redux";

export default function ChiefRecipeDetail() {
  const { getRecepieById, generatePdfForDish } = useRecepieDetails();
  const [pdfData, setPdfData] = useState<any>({});
  const [dishDetails, setDishDetails] = useState<any>({});

  const location = useLocation();
  const { dishId } = location.state || {};
  useEffect(() => {
    if (dishId) {
      getRecepieById(dishId, setDishDetails);
    }
  }, [dishId]);

  const handlePrint = async (dishId: any) => {
    console.log("Dish Id From Index", dishId);
    const response = await generatePdfForDish(dishId, setPdfData);
    if (response?.status) {
      window.open(response.pdfPath, "_blank");
    } else {
      console.error("Failed to generate PDF");
    }
  };
  console.log("From Dish Details");
  return (
    <ViewDetail>
      <div className="title no-print">
        <h1>Preparation Detail</h1>
        <Button
          title="Download PDF"
          width="16rem"
          fill="true"
          backgroundcolor="#007AFF"
          onclick={() => handlePrint(dishId)}
        />
      </div>
      {dishDetails && Array.isArray(dishDetails) ? (
        dishDetails.map((item: any, dishIndex: number) => (
          <div className="print-container print-only content">
            <div className="view-detail-top-section">
              <div>
                <h2>{item?.homeCookName} </h2>
              </div>
              <div className="date-time-container">
                <div className="date-time">
                  <img src={calender} alt="img" />
                  <h1>{new Date(item.createdAt).toLocaleDateString()}</h1>
                </div>
                <div className="date-time">
                  <img src={time} alt="img" />
                  <h1>
                    {item.createdAt &&
                      new Date(item.createdAt)
                        .toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                        .toUpperCase()}
                  </h1>
                </div>
              </div>
            </div>
            <ReceipeDetail>
              <div>
                <h2>{item?.name}</h2>
                <p>
                  {item?.serving} Servings | Prep Time {item?.preparationTime}{" "}
                  mins | Total Time: 1 Hour
                </p>
                <h1>Ingredients</h1>
                <ul style={{ listStyleType: "none" }}>
                  {item?.ingredients?.map((ing: any, index: number) => (
                    <li key={index}>{ing.ingredientName}</li>
                  ))}
                </ul>
                <h1>Pre-Cooking Steps</h1>
                <ul>
                  {item?.preCookingSetUp
                    ?.split("\n")
                    .map((step: string, index: number) => (
                      <li key={index}>{step.replace(/^\d+\./, "").trim()}</li>
                    ))}
                </ul>
                <h1>Pre-Cooking Setup</h1>
                <ul>
                  {item?.preCookingSetUp
                    ?.split("\n")
                    .map((step: string, index: number) => (
                      <li key={index}>{step.replace(/^\d+\./, "").trim()}</li>
                    ))}
                </ul>
              </div>
              <div className="receipe-img">
                <img src={item?.coverImage[0]} alt="img" />
                <div className="receipe-img-detail">
                  {item?.dietaries?.map((dietary: any, index: number) => (
                    <div className="receipe-imag-detail-cart" key={index}>
                      <span
                        style={{
                          width: "30px",
                          height: "27px",
                          display: "flex",
                          borderRadius: "1rem",
                          border: "1px solid #808D9E",
                        }}
                      ></span>
                      <span>{dietary.dietaryName}</span>
                    </div>
                  ))}
                </div>
              </div>
            </ReceipeDetail>
          </div>
        ))
      ) : (
        <h1>Loading...</h1>
      )}
    </ViewDetail>
  );
}
