import styled from "styled-components";

export const Container = styled.div`
width: 100%;
height: auto;
background-color:var(--white);
padding:1rem ;
border-radius: 1rem;
display: flex;
flex-direction: column;
gap: 2rem;
`