import HomeChef from "containers/home-chefs/home-chef-list";
import { Apis } from "libs/apis";

export const usePremiumHonmeCook = () => {
  const promotHomeCook = async (
    homeCookId: string,
    body: any,
    setPremiunmHomeCookData: any
  ) => {
    try {
      const res = await Apis.promoteHomeCook(homeCookId, body);
      setPremiunmHomeCookData(res.data);
    } catch (error) {
      console.error("Error in promoting home cook", error);
      return error;
    }
  };
  return { promotHomeCook };
};
