import { Apis } from "libs/apis";

export const manageAdmin = () => {
  const getUserByToken = async (setAdminProfile: any) => {
    try {
      const response = await Apis.getUserByToken();
      setAdminProfile(response.data.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setAdminProfile(null);
    }
  };
  return { getUserByToken };
};
