import { FC, Fragment, useEffect, useState } from "react";
import { Container, StatusTabeContainer, CardContainer, Status } from "./style";
import Pagination from "components/particles/pagination/pagination";
import { setRetailerStatusActiveButton } from "store/user-slice";
import { useSelector } from "react-redux";

import email from "assets/images/email.svg";
import phone from "assets/images/phone.svg";
import globe from "assets/images/globe.svg";
import locationIcon from "assets/images/chef-location.svg";
import bg from "assets/images/retailer-bg.svg";
import Button from "components/particles/primary-button";

import NoDataFound from "components/particles/no-data-found";
import { siteRoutes } from "utils/helpers/enums/routes.enums";
import { useLocation, useNavigate } from "react-router-dom";
import { tableData } from "utils/helpers/dummyData";

import view from "assets/images/view-icon.svg";
import StatusTabe from "components/particles/status-tabe";
import { RootState, dispatch } from "store";
import rating from "assets/images/RatingStar.svg";
import { useForm } from "react-hook-form";
import { RetailerViewSearchDTO } from "utils/helpers/models/retailer.dto";
import useRetailers from "../listing/useHook";
import { useRetailersById } from "./useHook";

const RetailerView: FC = () => {
  const location = useLocation();
  const { state } = location;
  const [dataById, setDataByID] = useState<any[]>();
  const [orderRetailerID, setOrderRetailerID] = useState<any[]>([]);
  const [orderPaginationState, setOrderPaginationState] = useState<any>({
    totalItems: 0,
    offset: 0,
    limit: 10,
  });
  const [filteredOrderRetailerID, setFilteredOrderRetailerID] = useState<any[]>(
    []
  );
  console.log("orderPaginationState", orderPaginationState);
  const { retailerStatusActiveButton, isLoading } = useSelector(
    (state: RootState) => state.user
  );
  const {
    register,
    handleSubmit,
    setValue,
    reset: formReset,
  } = useForm<RetailerViewSearchDTO>();

  const navigate = useNavigate();
  const { getRetailersById, getOrderByRetailerById } = useRetailersById();

  const search = (data: RetailerViewSearchDTO) => {
    const { name, id, date } = data;

    const filteredData = orderRetailerID.filter((item: any) => {
      const matchesName = name
        ? item.customerName
            ?.trim()
            .toLowerCase()
            .includes(name.trim().toLowerCase())
        : true;
      const matchesId = id ? item.orderId === id.trim() : true;
      const matchesDate = date ? item.pickUpDate === date : true;

      return matchesName && matchesId && matchesDate;
    });

    setFilteredOrderRetailerID(filteredData);
  };

  const resetSearch = () => {
    setValue("name", "");
    setValue("id", "");
    setValue("date", "");
    setFilteredOrderRetailerID(orderRetailerID);
    formReset();
  };

  const handleRetailorViewPage = (id: string) => {
    navigate(siteRoutes.retailerViewPrint, { state: { id } });
  };

  useEffect(() => {
    if (state?.id) {
      getRetailersById(state.id, setDataByID);
    }
  }, [state?.id]);

  const limit = 10;
  const fetchNextRecords = (offset: number) => {
    if (state?.id) {
      const status = state?.status;
      const retailerId = state?.id;

      getOrderByRetailerById(
        setOrderRetailerID,
        retailerId,
        status,
        (paginationState: any) => {
          setOrderPaginationState((prevState: any) => ({
            ...prevState,
            offset,
            totalItems: paginationState.totalItems,
          }));
        },
        offset,
        limit
      );
    }
  };

  useEffect(() => {
    if (state?.id) {
      const status = state?.status;
      const retailerId = state?.id;

      getOrderByRetailerById(
        setOrderRetailerID,
        retailerId,
        status,
        setOrderPaginationState,
        0, // Initial offset
        limit // Items per page
      );
    }
  }, [state?.id, state?.status]);

  useEffect(() => {
    if (retailerStatusActiveButton === "All") {
      setFilteredOrderRetailerID(orderRetailerID);
    } else {
      const filteredData = orderRetailerID.filter(
        (item) => item.status === retailerStatusActiveButton
      );
      setFilteredOrderRetailerID(filteredData);
    }
  }, [orderRetailerID, retailerStatusActiveButton]);

  const statusTabs: string[] = ["All", "Completed", "Expired", "Rejected"];

  return (
    <Container>
      <h1>Retailers list</h1>

      <CardContainer>
        {dataById?.map((retailer: any, index: number) => (
          <div key={index}>
            <div className="retailer-bg-img">
              <img src={bg} alt="img" />
            </div>
            <div
              style={{ display: "flex", width: "100%", alignItems: "center" }}
            >
              <div className="user-profile">
                <img
                  src={retailer.image}
                  alt="img"
                  style={{
                    width: "200px",
                    borderRadius: "50%",
                    height: "200px",
                  }}
                />
                <div className="user-detail">
                  <h1>
                    {retailer.firstName}
                    {retailer.lastName}
                  </h1>
                  <p>{retailer.roleInStore}</p>
                  <div className="contect-list">
                    <div>
                      <img src={email} alt="" />
                      <p>{retailer.email}</p>
                    </div>
                    <div>
                      <img src={phone} alt="" />
                      <p>+{retailer.mobileNumber}</p>
                    </div>
                    <div>
                      <img src={globe} alt="" />
                      <p>{retailer.websiteUrl}</p>
                    </div>
                    <div>
                      <img src={locationIcon} alt="" />
                      <p>{retailer.physicalAddress}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </CardContainer>

      <StatusTabeContainer>
        {statusTabs.map((tab: string, index: number) => (
          <StatusTabe
            key={index}
            active={retailerStatusActiveButton === tab}
            onclick={() => {
              dispatch(setRetailerStatusActiveButton(tab));
            }}
            title={tab}
          />
        ))}
      </StatusTabeContainer>

      <div className="search-container">
        <div className="input-fields">
          <input
            type="text"
            placeholder="Search By Name"
            {...register("name")}
          />
          <input
            type="text"
            placeholder="Search By Order Id"
            {...register("id")}
          />
          {/* <input
            type="date"
            name="date"
            id="date"
          /> */}
          <div className="custom-date-input-container">
            <input
              type="date"
              className="custom-date-input"
              placeholder="Search by Date"
              {...register("date")}
            />
          </div>
        </div>
        <div className="button-fields">
          <Button
            title="Search"
            width="16rem"
            fill="true"
            onclick={handleSubmit(search)}
          />
          <Button
            title="Reset"
            width="16rem"
            fill="true"
            backgroundcolor="var(--reset-button)"
            onclick={handleSubmit(resetSearch)}
          />
        </div>
      </div>

      <div className="data-table">
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th className="th">Order Id</th>
              <th className="th">Items</th>
              <th scope="col">Time/Date</th>
              <th className="th">Amount</th>
              {retailerStatusActiveButton === "All" && (
                <th className="th">Status</th>
              )}
              {retailerStatusActiveButton === "Complete" && (
                <th className="th">Rate</th>
              )}
              <th className="th">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrderRetailerID?.length > 0 ? (
              filteredOrderRetailerID.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    <img
                      src={item.customerImage}
                      alt="img"
                      width={30}
                      className="img"
                    />
                  </td>
                  <td>{item.customerName}</td>
                  <td className="th">{item.generatedOrderId}</td>
                  <td className="th">{item.quentity}</td>
                  <td>{item.pickUpDate}</td>
                  <td className="th">{item.totalAmount}</td>
                  {retailerStatusActiveButton === "All" && (
                    <td className="th">
                      <Status active={item.status}>{item.status}</Status>
                    </td>
                  )}
                  {retailerStatusActiveButton === "Complete" && (
                    <td className="th">
                      <img src={rating} alt="img" />
                      {item.id}
                    </td>
                  )}
                  <td className="th">
                    <div
                      className="view-container"
                      onClick={() =>
                        handleRetailorViewPage(item.generatedOrderId)
                      }
                    >
                      <img src={view} alt="img" />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <NoDataFound isShow={!isLoading} />
            )}
          </tbody>
        </table>
      </div>

      <Fragment>
        <Pagination
          totalRecords={orderPaginationState.totalItems}
          perPage={limit}
          handleFetchNextRecords={fetchNextRecords}
        />
      </Fragment>
    </Container>
  );
};

export default RetailerView;
