import { db } from "./firebase.config";
import { collection, getDocs, doc, writeBatch } from "firebase/firestore";

// export const getNotifications = async () => {
//   const notificationsCollection = collection(db, "notifications");
//   const snapshot = await getDocs(notificationsCollection);
//   return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
// };

export const getNotifications = async () => {
  const notificationsCollection = collection(db, "notifications");
  const snapshot = await getDocs(notificationsCollection);
  return snapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      isRead: data.isRead ?? false,
      ...data,
    };
  });
};

export const markNotificationsAsRead = async () => {
  const notificationsCollection = collection(db, "notifications");
  const snapshot = await getDocs(notificationsCollection);
  const batch = writeBatch(db);

  snapshot.forEach((doc) => {
    const notifDoc = doc.ref;
    batch.update(notifDoc, { isRead: true });
  });

  await batch.commit();
};
