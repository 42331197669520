import { FC, Fragment, useEffect, useState } from "react";
import { Container, TabeContainer, TabeCart } from "./style";
import Pagination from "components/particles/pagination/pagination";

import orderIcon from "assets/images/order-white-icon.svg";
import sessionDarkIcon from "assets/images/session-dark-icon.svg";
import orderDarkIcon from "assets/images/order-dark-icon.svg";
import sessionIcon from "assets/images/session-white-icon.svg";
import { useDispatch, useSelector } from "react-redux";

import { setManageStatusActiveButton } from "store/user-slice";
import Button from "components/particles/primary-button";
import { tableData } from "utils/helpers/dummyData";
import NoDataFound from "components/particles/no-data-found";

import view from "assets/images/view-icon.svg";
import { Status } from "containers/home-chefs/view/styled";
import rating from "assets/images/RatingStar.svg";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enums";
import { set, useForm } from "react-hook-form";
import {
  manageStateOrderSearchDTO,
  manageStateSessionSearchDTO,
} from "utils/helpers/models/manageState.dto";
import useActivityManagement from "./useHook";

interface ManageStateProps {}

interface SearchQuery {
  name: string;
  email: string;
  gender: string;
}

const ManageState: FC<ManageStateProps> = () => {
  const { manageStateActiveButton, isLoading } = useSelector(
    (state: any) => state.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset: formReset,
  } = useForm<manageStateOrderSearchDTO | manageStateSessionSearchDTO>();
  const [sessionData, setSessionData] = useState<any>([]);
  const [orderData, setOrderData] = useState<any>([]);
  const [sessionPaginationState, setSessionPaginationState] = useState<any>();
  const [orderPaginationState, setOrderPaginationState] = useState<any>();
  console.log("Order Pagination State", orderPaginationState);
  const handleViewPage = (orderId: string) => {
    navigate(siteRoutes.manageStatePrint, { state: { orderId } });
  };

  const {
    getOrderDetails,
    sessionDetails,
    getSessionByUserById,
    getOrderByUserById,
  } = useActivityManagement();

  useEffect(() => {
    const paginationState = { offset: 0, limit: 10 };
    sessionDetails(setSessionData, setSessionPaginationState, paginationState);
    getOrderDetails(setOrderData, setOrderPaginationState, paginationState);
  }, []);

  useEffect(() => {
    formReset({
      id: "",
      date: "",
      cook: "",
      retailer: "",
      status: "",
    });
  }, [manageStateActiveButton, formReset]);

  const search = (
    data: manageStateOrderSearchDTO | manageStateSessionSearchDTO
  ) => {
    if (manageStateActiveButton === "Session Details") {
      getSessionByUserById(
        data.id,
        data.date,
        setSessionData,
        data.cook || "",
        data.status || ""
      );
    } else if (manageStateActiveButton === "Order Details") {
      getOrderByUserById(data.id, data.date, data.retailer, setOrderData);
    }
  };

  const reset = () => {
    const paginationState = { offset: 0, limit: 10 };
    formReset({
      id: "",
      date: "",
      cook: "",
      retailer: "",
      status: "",
    });

    if (manageStateActiveButton === "Session Details") {
      sessionDetails(
        setSessionData,
        setSessionPaginationState,
        paginationState
      );
    } else if (manageStateActiveButton === "Order Details") {
      getOrderDetails(setOrderData, setOrderPaginationState, paginationState);
    }
  };

  const limit = 10;

  function fetchNextRecords(offset: number) {
    const paginationState = { offset, limit: 10 };
    if (manageStateActiveButton === "Session Details") {
      setSessionPaginationState((prevState: any) => ({
        ...prevState,
        offset: offset,
      }));
      console.log("Session Pagination State", paginationState);
      sessionDetails(
        setSessionData,
        setSessionPaginationState,
        paginationState
      );
    } else if (manageStateActiveButton === "Order Details") {
      setOrderPaginationState((prevState: any) => ({
        ...prevState,
        offset: offset,
      }));
      console.log("Order Pagination State", paginationState);
      getOrderDetails(setOrderData, setOrderPaginationState, paginationState);
    }
  }

  const options = ["", "completed", "All", "pending", "rejected", "incoming"];

  return (
    <Container>
      <div className="title">
        <h1>States list</h1>
      </div>

      <TabeContainer>
        <TabeCart
          active={manageStateActiveButton === "Order Details"}
          onClick={() => {
            dispatch(setManageStatusActiveButton("Order Details"));
          }}
        >
          <div className="categories-tab-img">
            <img
              src={
                manageStateActiveButton === "Order Details"
                  ? orderIcon
                  : orderDarkIcon
              }
              alt="img"
            />
          </div>
          <h1>Order Details</h1>
        </TabeCart>
        <TabeCart
          active={manageStateActiveButton === "Session Details"}
          onClick={() => {
            dispatch(setManageStatusActiveButton("Session Details"));
          }}
        >
          <div className="categories-tab-img">
            <img
              src={
                manageStateActiveButton === "Session Details"
                  ? sessionIcon
                  : sessionDarkIcon
              }
              alt="img"
            />
          </div>
          <h1>Session Details</h1>
        </TabeCart>
      </TabeContainer>

      <div className="search-container">
        <div className="input-fields">
          {manageStateActiveButton === "Order Details" ? (
            <>
              <input
                type="text"
                placeholder="Search by Order id"
                {...register("id")}
              />
              <div className="custom-date-input-container">
                <input
                  type="date"
                  className="custom-date-input"
                  placeholder="Search by Date"
                />
              </div>
              <input
                type="text"
                placeholder="Search By Retailer"
                {...register("retailer")}
              />
            </>
          ) : (
            <>
              <input
                type="text"
                placeholder="Search by Session id"
                {...register("id")}
              />
              <div className="custom-date-input-container">
                <input
                  type="date"
                  className="custom-date-input"
                  placeholder="Search by Date"
                  {...register("date")}
                />
              </div>
              <input
                type="text"
                placeholder="Search By Home Cook"
                {...register("cook")}
              />
              <select id="satus" {...register("status")}>
                <option value="">Status</option>
                {options.slice(1).map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
        <div className="button-fields">
          <Button
            title="Search"
            width="16rem"
            fill="true"
            onclick={handleSubmit(search)}
          />
          <Button
            title="Reset"
            width="16rem"
            fill="true"
            backgroundcolor="var(--reset-button)"
            onclick={handleSubmit(reset)}
          />
        </div>
      </div>
      <div className="data-table">
        <table>
          <thead>
            <tr>
              <th className="th">
                {manageStateActiveButton === "Order Details"
                  ? "Order ID"
                  : "Session ID"}
              </th>
              <th scope="col">Date</th>
              <th scope="col">Placed by</th>
              <th scope="col">
                {manageStateActiveButton === "Order Details"
                  ? "Retailer"
                  : "Home Cook"}
              </th>
              <th className="th">Amount</th>
              <th className="th">
                {manageStateActiveButton === "Order Details"
                  ? "Items"
                  : "Duration"}
              </th>
              {manageStateActiveButton === "Order Details" ? (
                ""
              ) : (
                <th className="th">Rating</th>
              )}
              <th className="th">
                {manageStateActiveButton === "Order Details"
                  ? "Actions"
                  : "Status"}
              </th>
            </tr>
          </thead>
          <tbody>
            {(manageStateActiveButton === "Order Details"
              ? orderData
              : sessionData
            )?.length > 0 ? (
              (manageStateActiveButton === "Order Details"
                ? orderData
                : sessionData
              ).map((item: any, index: number) => (
                <tr key={index}>
                  <td className="th">
                    {manageStateActiveButton === "Order Details"
                      ? item.generatedOrderId
                      : item.sessionId}
                  </td>
                  <td>
                    {manageStateActiveButton === "Order Details"
                      ? item.createdAt.split("T")[0]
                      : item.sessionDetails.placedOn.split("T")[0]}
                  </td>
                  <td>
                    {manageStateActiveButton === "Order Details"
                      ? item.customerName
                      : item.user.userName}
                  </td>
                  <td>
                    {manageStateActiveButton === "Order Details"
                      ? `${item.retailerInfo.firstName} ${item.retailerInfo.lastName}`
                      : item.homeCook?.homeCookName || "N/A"}
                  </td>

                  <td className="th">
                    {manageStateActiveButton === "Order Details"
                      ? Math.round(item.totalAmount)
                      : item.sessionDetails.sessionAmount}
                  </td>
                  <td className="th">
                    {manageStateActiveButton === "Order Details"
                      ? item.totalOrderedItems
                      : item.sessionDetails.sessionDuration}
                  </td>
                  {manageStateActiveButton === "Session Details" && (
                    <td className="th">
                      {item.sessionDetails.sessionRating || 0}
                    </td>
                  )}
                  <td className="actions th">
                    {manageStateActiveButton === "Order Details" ? (
                      <div
                        className="view-container"
                        onClick={() => handleViewPage(item.generatedOrderId)}
                      >
                        <img src={view} alt="img" />
                      </div>
                    ) : (
                      <Status active={item.status}>
                        {item.sessionDetails?.sessionStatus
                          ? item.sessionDetails.sessionStatus
                              .charAt(0)
                              .toUpperCase() +
                            item.sessionDetails.sessionStatus.slice(1)
                          : "N/A"}
                      </Status>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <NoDataFound isShow={!isLoading} />
            )}
          </tbody>
        </table>
      </div>
      <Fragment>
        <Pagination
          totalRecords={
            manageStateActiveButton === "Order Details"
              ? orderPaginationState?.totalOrders
              : sessionPaginationState?.totalSessions || 0
          }
          perPage={limit}
          handleFetchNextRecords={fetchNextRecords}
        />
      </Fragment>
    </Container>
  );
};

export default ManageState;
