import styled from "styled-components";

export const Container = styled.form`
.popup-title{
    width: 100%; 
    text-align: center; 

    h2{
        font-weight: 600;
    }
}
`