import { FC, useState, useEffect } from "react";
import Modal from "react-modal";
import close from "assets/images/close.svg";
import Button from "components/particles/primary-button";
import { usePremiumHonmeCook } from "./useHook";

import { Container } from "./style";

interface PromoteHomeCookModalProps {
  isOpen: boolean;
  action: "promote" | "demote" | null;
  onClose: () => void;
  homeCookId: string;
}

const PromoteHomeCookModal: FC<PromoteHomeCookModalProps> = ({
  isOpen,
  action,
  onClose,
  homeCookId,
}) => {
  const [homeCookData, setHomeCookData] = useState<any>({});
  const { promotHomeCook } = usePremiumHonmeCook();

  useEffect(() => {
    if (homeCookData) {
    }
  }, [homeCookData]);

  const handleYesClick = () => {
    const body = {
      upgradeToPremium: action === "promote" ? "Yes" : "No",
    };
    promotHomeCook(homeCookId, body, setHomeCookData);
    onClose();
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      fontSize: "2rem",
      width: "68.5rem",
      borderRadius: "3rem",
    },
  };

  const modalTitle =
    action === "promote"
      ? "Promote Home Cook as Premium"
      : "Demote Home Cook from Premium";

  return (
    <Modal isOpen={isOpen} style={customStyles} contentLabel="Home Cook Modal">
      <Container>
        <div className="close-button" onClick={onClose}>
          <img src={close} alt="Close button" />
        </div>
        <div className="model-container">
          <div
            className="popup-title"
            style={{ width: "100%", textAlign: "center", marginTop: "4rem" }}
          >
            <h1>{modalTitle}</h1>
          </div>
          <div>
            <div className="add-button">
              <Button
                title="Yes"
                width="25rem"
                backgroundcolor="var(--primary)"
                fill="true"
                onclick={handleYesClick}
              />
            </div>
            <div className="add-button">
              <Button
                title="No"
                width="25rem"
                backgroundcolor="var(--primary)"
                fill="true"
                onclick={onClose}
              />
            </div>
          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default PromoteHomeCookModal;
