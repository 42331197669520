import { Apis } from "libs/apis";

export const useSesssionDetails = () => {
  const getSessionByUserById = async (
    sessionId: string,
    setDishDetails: any
  ) => {
    await Apis.getSessionDetailsBySessionId(sessionId).then((res) => {
      setDishDetails(res.data.data);
    });
  };
  const generatePdfForUserSession = async (
    sessionId: string,
    setPdfData: any
  ) => {
    try {
      console.log("sessionId", sessionId);
      const body = {
        sessionId: sessionId,
      };
      const resposne = await Apis.generatePdfForUserSession(body);
      setPdfData(resposne.data);
      return resposne.data;
    } catch (error) {
      console.error("Error while generating pdf", error);
    }
  };
  return { getSessionByUserById, generatePdfForUserSession };
};
