import { Apis } from "libs/apis";

export const useRecepieDetails = () => {
  const getRecepieById = async (dishId: number, setDishDetails: any) => {
    await Apis.getDishByIdForHomeCook(dishId).then((res) => {
      setDishDetails(res.data.data);
    });
  };

  const generatePdfForDish = async (dishId: string, setPdfData: any) => {
    try {
      const body = {
        dishId: dishId,
      };
      const resposne = await Apis.generatePdfForDish(body);
      setPdfData(resposne.data);
      return resposne.data;
    } catch (error) {
      console.error("Error while generating pdf", error);
    }
  };
  return { getRecepieById, generatePdfForDish };
};
