import { Apis } from "libs/apis";

export const useDashboard = () => {
  const getUSerStats = async (setUserStats: any) => {
    try {
      const response = await Apis.getUserStats();
      setUserStats(response.data.data);
      return response.data;
    } catch (error) {
      console.error("Error while fetching user stats", error);
    }
  };
  const getGraphStats = async (
    ordersPeriod: any,
    setOrdersStats: Function,
    setSessionsStats: Function,
    setSalesGraphStats: Function
  ) => {
    try {
      const response = await Apis.getUserGraphStats(ordersPeriod);
      const { data: { data = [] } = {} } = response || {};
      const { sessions, orders } = data[0];
      setSalesGraphStats({ sessions, orders });
      setSessionsStats(sessions);
      setOrdersStats(orders);
      return response;
    } catch (error) {
      console.error("Error while fetching user stats", error);
    }
  };

  return { getUSerStats, getGraphStats };
};
