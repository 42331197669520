import { useSelector } from "react-redux";
import {
  CardContainer,
  Container,
  StatusTabeContainer,
  StatusTabeCart,
  StyledButton,
} from "./style";
import { dispatch } from "store";
import { setDishStatusActiveButton } from "store/user-slice";
import ReceipeOverview from "./overview";
import Ingredients from "./ingredients";
import Nutrition from "./nutrition";
import Reviews from "./reviews";
import NoDataFound from "components/particles/no-data-found";
import Button from "components/particles/primary-button";
import { siteRoutes } from "utils/helpers/enums/routes.enums";
import { useLocation, useNavigate } from "react-router-dom";
import useGetDish from "./useHook";
import { useEffect, useState } from "react";
export default function ViewReceipe() {
  const { dishStatusActiveButton, isLoading } = useSelector(
    (state: any) => state.user
  );
  const [dishData, setDishData] = useState<any>(null);
  const { getDishByUserId } = useGetDish();

  const navigate = useNavigate();
  const location = useLocation();
  const { dishId } = location.state || {};

  const handlePageView = (dishId: number) => {
    navigate(siteRoutes.homeChefViewReceipeDetails, { state: { dishId } });
  };

  useEffect(() => {
    getDishByUserId(dishId, setDishData);
  }, [dishId]);

  return (
    <Container>
      <h1>Dish</h1>
      <CardContainer>
        {Array.isArray(dishData) && dishData.length > 0 ? (
          dishData.map((dish: any, index: number) => (
            <CardContainer key={dish.dishId}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}
              >
                <div className="user-profile">
                  <img
                    src={dish.coverImage[0]}
                    alt="Dish Cover"
                    height={170}
                    width={260}
                    style={{ borderRadius: "10px" }}
                  />
                  <div className="user-detail">
                    <div className="receipe-title">
                      <div>
                        <h1>{dish.name}</h1>
                        <p>By {dish.homeCookName}</p>
                      </div>
                      <div>
                        <StyledButton
                          onClick={() => handlePageView(dish.dishId)}
                          style={{
                            backgroundColor: "var(--primary)",
                            height: "5rem",
                            width: "100%",
                            padding: "1.5rem 2.5rem",
                            borderRadius: "5px",
                            color: "white",
                            cursor: "pointer",
                            textAlign: "center",
                            alignItems: "center",
                          }}
                        >
                          Preparation Details
                        </StyledButton>
                      </div>
                    </div>
                    <div className="date-rate">
                      <div className="date">
                        <p>Preparation Time</p>
                        <h2>{dish.preparationTime} Minutes</h2>
                      </div>
                      <div className="rate">
                        <p>Rating</p>
                        <h2>{dish.dishRating}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardContainer>
          ))
        ) : (
          <p>No data found</p>
        )}
      </CardContainer>

      <StatusTabeContainer>
        <StatusTabeCart
          active={dishStatusActiveButton === "Overview"}
          onClick={() => {
            dispatch(setDishStatusActiveButton("Overview"));
          }}
        >
          <h2>Overview</h2>
        </StatusTabeCart>
        <StatusTabeCart
          active={dishStatusActiveButton === "Ingredients"}
          onClick={() => {
            dispatch(setDishStatusActiveButton("Ingredients"));
          }}
        >
          <h2>Ingredients</h2>
        </StatusTabeCart>
        <StatusTabeCart
          active={dishStatusActiveButton === "Nutrition"}
          onClick={() => {
            dispatch(setDishStatusActiveButton("Nutrition"));
          }}
        >
          <h2>Nutrition</h2>
        </StatusTabeCart>
        <StatusTabeCart
          active={dishStatusActiveButton === "Reviews"}
          onClick={() => {
            dispatch(setDishStatusActiveButton("Reviews"));
          }}
        >
          <h2>Reviews</h2>
        </StatusTabeCart>
      </StatusTabeContainer>
      {dishStatusActiveButton === "Overview" ? (
        <ReceipeOverview dishData={dishData} />
      ) : dishStatusActiveButton === "Ingredients" ? (
        <Ingredients dishData={dishData} />
      ) : dishStatusActiveButton === "Nutrition" ? (
        <Nutrition dishData={dishData} />
      ) : dishStatusActiveButton === "Reviews" ? (
        <Reviews dishData={dishData} />
      ) : (
        <NoDataFound isShow={!isLoading} />
      )}
    </Container>
  );
}
