import { Apis } from "libs/apis";

interface UpdatePasswordBody {
  currentPassword: string;
  newPassword: string;
}
export const useUpdatePassword = () => {
  const updatePassword = async (body: UpdatePasswordBody) => {
    try {
      console.log("Request Body:", body);
      const res = await Apis.updatePassword(body);
      console.log("API response:", res);
    } catch (error: any) {
      console.error("API error response:", error.response);
    }
  };
  return { updatePassword };
};
