import axios from "axios";
import { Apis } from "libs/apis";
interface Filters {
  name?: string;
  mobileNumber?: string;
  gender?: string;
  status?: string;
}

export const useUsers = () => {
  const getUsers = async (
    setData: any,
    setUserPaginationState: Function,
    offset: number = 0,
    limit: number = 10
  ) => {
    await Apis.getUsers(offset, limit)
      .then((res) => {
        setData(res.data.data);
        setUserPaginationState(res.data.pagination);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateUserStatusById = async (
    userId: string,
    setUsersData: Function,
    userData: any[],
    status: boolean
  ) => {
    try {
      const res = await Apis.putUsers(userId, { status });

      if (res.data.status) {
        console.log("User status updated successfully:", res.data.updatedUser);

        const updatedUserData = userData.map((user) =>
          user.id === userId ? { ...user, status } : user
        );

        setUsersData(updatedUserData);
      }
    } catch (err) {
      console.error("Error updating user status:", err);
    }
  };
  const getUser = (
    setData: React.Dispatch<React.SetStateAction<any[]>>,
    filters: Filters
  ) => {
    const queryParams = new URLSearchParams(
      filters as Record<string, string>
    ).toString();
    Apis.getUser(queryParams)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return { getUsers, updateUserStatusById, getUser };
};
