import { LineLoaderMain } from "./style";



const LineLoader = () => {
  return (
    <LineLoaderMain>
      <div className="loader-line"></div>
    </LineLoaderMain>
  );
};

export default LineLoader;
