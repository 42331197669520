import Button from "components/particles/primary-button";
import {
  Container,
  CardContainer,
  CardContainerTitle,
  TableContainer,
} from "./styled";
import { tableData } from "utils/helpers/dummyData";
import NoDataFound from "components/particles/no-data-found";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useOrderDetails } from "./useHook";

export default function ManageStatePrint() {
  const { isLoading } = useSelector((state: any) => state.user);

  const { getOrderByUserById } = useOrderDetails();
  const [orderDetails, setOrderDetails] = useState<any>({});

  const location = useLocation();
  const { orderId } = location.state || {};

  useEffect(() => {
    if (orderId) {
      getOrderByUserById("", orderId, setOrderDetails);
    }
  }, [orderId]);

  const handlePrint = () => {
    window.print();
  };

  const renderIngredients = (item: any) => {
    const allIngredients = [
      ...(item.dishes?.flatMap((dish: any) => dish.dishIngredients) || []),
      ...(item.miscellaneous || []),
    ];
    return (
      <tbody>
        {allIngredients.map((ingredient: any, index: number) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{ingredient.ingredientName}</td>
            <td>{ingredient.itemQuantity || 0}</td>
            <td>
              {ingredient.cartViewIngredientQuantity}{" "}
              {ingredient.cartViewIngredientUnit}
            </td>
            <td>${parseFloat(ingredient.itemPrice).toFixed(2)}</td>
            <td>
              $
              {(
                parseFloat(ingredient.itemPrice) * ingredient.itemQuantity
              ).toFixed(2)}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  const columns = [
    "Sr. No",
    "Product Name",
    "Product Quantity",
    "Product Weight",
    "Product Price",
    "Product Total",
  ];

  return (
    <>
      {orderDetails && Array.isArray(orderDetails) ? (
        orderDetails.map((item: any, dishIndex: number) => (
          <Container>
            <h1 className="no-print">Invoice</h1>
            <div className="print-container print-only content">
              <CardContainerTitle>
                <div>
                  <h1>{"Invoice".toLocaleUpperCase()}</h1>
                  <p>{item?.generatedOrderId}</p>
                </div>
                <div className="no-print">
                  <Button
                    title="Print"
                    width="12rem"
                    backgroundcolor="#007AFF"
                    fill="true"
                    onclick={handlePrint}
                  />
                </div>
              </CardContainerTitle>
              <CardContainer>
                <div>
                  <p>{"BILL FROM:"}</p>
                  <h1>
                    {item?.retailerInfo.firstName} {item?.retailerInfo.lastName}
                  </h1>
                  <p>{item?.retailerInfo.physicalAddress}</p>
                </div>
                <div>
                  <p>{"BILL TO:"}</p>
                  <h1>{item?.customerName}</h1>
                  <p>{item?.customerAddress}</p>
                </div>
                <div>
                  <div>
                    <p>
                      Date:{" "}
                      {new Date(item?.pickUpDate).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                  <div>
                    <p>
                      Time:{" "}
                      {item?.pickUpTime
                        ? (() => {
                            const timeString = item.pickUpTime;
                            const validTime = `1970-01-01T${timeString}Z`;
                            return new Date(validTime).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            );
                          })()
                        : "Invalid Time"}
                    </p>
                  </div>
                </div>
              </CardContainer>
              <TableContainer>
                <div className="data-table">
                  <table>
                    <thead>
                      <tr>
                        {columns.map((column: string, index: number) => {
                          return <th key={index}>{column}</th>;
                        })}
                      </tr>
                    </thead>
                    {item.dishes?.length > 0 ||
                    item.miscellaneous?.length > 0 ? (
                      <>
                        {renderIngredients(item)}
                        <div className="total">
                          <div className="total-cart">
                            <h5>Total</h5>
                            <h1>${item?.totalAmount.toFixed(0)}</h1>
                          </div>
                        </div>
                      </>
                    ) : (
                      <NoDataFound isShow={!isLoading} />
                    )}
                  </table>
                </div>
              </TableContainer>
            </div>
          </Container>
        ))
      ) : (
        <h1>Loading...</h1>
      )}
    </>
  );
}
