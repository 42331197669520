import { FC, useRef, useState, useEffect } from "react";
import Modal from "react-modal";
import close from "assets/images/close.svg";
import camera from "assets/images/camera.svg";
import {
  ProfileImageContainer,
  ProfileImageWrapper,
  ProfileImage,
  EditIconContainer,
  Container,
} from "./style";
import Button from "components/particles/primary-button";
import { useForm } from "react-hook-form";
import { ProfileDTO } from "utils/helpers/models/profile.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { manageAdmin } from "./useHook";

interface ProfileModelProps {
  setIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  initialData?: ProfileDTO | null;
  onProfileUpdated?: () => void;
  userData?: ProfileDTO | any;
}

const ProfilePopup: FC<ProfileModelProps> = ({
  modalIsOpen,
  setIsOpen,
  initialData,
  onProfileUpdated,
  userData,
}) => {
  const [previewImage, setPreviewImage] = useState<string | null>(
    userData?.image || initialData?.image || null
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { updateAdmin } = manageAdmin();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      fontSize: "2rem",
      width: "68.5rem",
      borderRadius: "3rem",
    },
  };

  const closeModal = () => setIsOpen(false);

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ProfileDTO>({
    defaultValues: userData || initialData || {},
  });

  useEffect(() => {
    if (userData) {
      setValue("name", userData.name);
      setValue("email", userData.email);
      setValue("mobileNumber", userData.mobileNumber);
    }
  }, [userData, setValue]);

  const onSubmit = async (data: ProfileDTO) => {
    try {
      const formData = new FormData();
      formData.append("name", data.name || "");
      formData.append("email", data.email || "");
      formData.append("mobileNumber", data.mobileNumber || "");

      if (fileInputRef.current?.files?.[0]) {
        formData.append("image", fileInputRef.current.files[0]);
      }

      await updateAdmin(formData);

      if (onProfileUpdated) onProfileUpdated();

      setIsOpen(false);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Edit Profile Modal"
    >
      <Container onSubmit={handleSubmit(onSubmit)}>
        <button className="close-button" onClick={closeModal}>
          <img src={close} alt="Close" />
        </button>
        <div className="model-container">
          <div className="popup-title">
            <h1>Edit Profile</h1>
          </div>
          <div className="add-ingredient-container">
            <ProfileImageWrapper>
              <ProfileImageContainer>
                <ProfileImage
                  src={previewImage || "https://picsum.photos/100"}
                  alt="avatar"
                  onClick={handleImageClick}
                />
                <EditIconContainer onClick={handleImageClick}>
                  <img src={camera} alt="Edit" />
                </EditIconContainer>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </ProfileImageContainer>
            </ProfileImageWrapper>
            <div>
              <input
                type="text"
                placeholder="Name"
                {...register("name", { required: "Name is required" })}
              />
              <FormErrorMessage error={errors.name} />
            </div>
            <div>
              <input
                type="email"
                placeholder="Email"
                {...register("email", { required: "Email is required" })}
                disabled
              />
              <FormErrorMessage error={errors.email} />
            </div>
            <div>
              <input
                type="text"
                placeholder="Mobile Number"
                {...register("mobileNumber", {
                  required: "Mobile number is required",
                })}
              />
              <FormErrorMessage error={errors.mobileNumber} />
            </div>
          </div>

          <div className="add-button">
            <Button
              title="Update"
              width="51.2rem"
              backgroundcolor="var(--primary)"
              fill="true"
            />
          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default ProfilePopup;
