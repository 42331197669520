import { FC, Fragment, useEffect, useState } from "react";
import { Container } from "./style";
import Pagination from "components/particles/pagination/pagination";
import Button from "components/particles/primary-button";
import NoDataFound from "components/particles/no-data-found";
import { ChangeEvent } from "react";

import { useNavigate } from "react-router-dom";
import view from "assets/images/view-icon.svg";
import Swal from "sweetalert2";
import { useUsers } from "./useHook";
import { useSelector } from "react-redux";

import { siteRoutes } from "utils/helpers/enums/routes.enums";
import { useForm } from "react-hook-form";
import { UsersListSearchDTO } from "utils/helpers/models/users.dto";
import { UserDTO } from "utils/helpers/models/user.dto";

const UserList: FC = () => {
  const [usersData, setUsersData] = useState<any[]>([]);
  const isLoading = useSelector((state: any) => state.user.isLoading);

  const { register, handleSubmit, setValue, getValues } =
    useForm<UsersListSearchDTO>();
  const { getUsers, updateUserStatusById, getUser } = useUsers();
  const [userPaginationState, setUserPaginationState] = useState<any>([]);
  const navigate = useNavigate();

  const search = (data: UsersListSearchDTO) => {
    getUser(setUsersData, {
      name: data.nameOrEmail || "",
      mobileNumber: data.phoneNumber || "",
      gender: data.gender || "",
      status: data.status || "",
    });
  };
  const reset = () => {
    setValue("nameOrEmail", "");
    setValue("phoneNumber", "");
    setValue("gender", "");
    setValue("status", "");
    getUser(setUsersData, {});
  };

  const limit = 10;

  const fetchNextRecords = (offset: number) => {
    getUsers(setUsersData, setUserPaginationState, offset, limit);
  };
  useEffect(() => {
    getUsers(setUsersData, setUserPaginationState);
  }, []);

  const handleViewPage = (id: string) => {
    navigate(siteRoutes.usersView, { state: { id } });
  };

  const toggleUserStatus = async (
    userId: string,
    setUserStatus: Function,
    userData: any[]
  ) => {
    const currentUser = userData.find((user) => user.id === userId);
    if (currentUser) {
      const newStatus = !currentUser.status;
      await updateUserStatusById(userId, setUserStatus, userData, newStatus);
    }
  };

  const columns: any = [
    {
      name: "Name",
      class: "",
    },
    {
      name: "Email",
      class: "",
    },
    {
      name: "Phone Number",
      class: "",
    },
    {
      name: "Gender",
      class: "th",
    },
    {
      name: "Location",
      class: "th",
    },
    {
      name: "Status",
      class: "th",
    },
    {
      name: "Actions",
      class: "th",
    },
  ];

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const genderOptions = ["", "Male", "Female"];
  const statusOptions = ["", "True", "False"];
  return (
    <Container>
      <h1>User List</h1>
      <div className="search-container">
        <div className="input-fields">
          <input
            type="text"
            placeholder="Search By Name"
            {...register("nameOrEmail")}
          />
          <input
            type="text"
            placeholder="Search By Phone Number"
            {...register("phoneNumber")}
          />
          <select id="gender" {...register("gender")}>
            <option value="">Gender</option>
            {genderOptions.slice(1).map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
          <select id="status" {...register("status")}>
            <option value="">Status</option>
            {statusOptions.slice(1).map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="button-fields">
          <Button
            title="Search"
            width="16rem"
            fill="true"
            onclick={handleSubmit(search)}
          />
          <Button
            title="Reset"
            width="16rem"
            fill="true"
            backgroundcolor="var(--reset-button)"
            onclick={handleSubmit(reset)}
          />
        </div>
      </div>
      <div className="data-table">
        <table>
          <thead>
            <tr>
              {columns.map((column: any, index: number) => {
                return (
                  <th className={column.class} key={index}>
                    {column.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {usersData?.length > 0 ? (
              usersData?.map((user: UserDTO, index) => (
                <tr key={index}>
                  <td>
                    <img
                      src={
                        user?.image ? user?.image : "https://picsum.photos/200"
                      }
                      alt="img"
                      className="img"
                    />
                    {user?.name}
                  </td>
                  <td>{user?.email}</td>
                  <td>{user?.mobileNumber}</td>
                  <td className="th">{user.gender || "Null"}</td>
                  <td className="th">{user.location || "Null"}</td>
                  <td>
                    <label className="toggle-button">
                      <input
                        type="checkbox"
                        checked={user.status}
                        onChange={(e) => {
                          console.log("Checked value:", e.target.checked);
                          toggleUserStatus(user.id, setUsersData, usersData);
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </td>
                  <td className="th">
                    <div
                      className=" view-container"
                      onClick={() => handleViewPage(user?.id)}
                    >
                      <img src={view} alt="img" />
                    </div>
                    {/* <img src={deleteIcon} alt='img' onClick={HandleDelete}/> */}
                  </td>
                </tr>
              ))
            ) : (
              <NoDataFound isShow={!isLoading} />
            )}
          </tbody>
        </table>
        {/* )} */}
      </div>
      <Fragment>
        <Pagination
          totalRecords={userPaginationState.total}
          perPage={limit}
          handleFetchNextRecords={fetchNextRecords}
        />
      </Fragment>
    </Container>
  );
};

export default UserList;
