import { Apis } from "libs/apis";

export const useDishReview = () => {
  const getReviewsForDish = async (
    homeCookId: string,
    dishId: number,
    setReviewData: any
  ) => {
    try {
      const response = await Apis.getReviewsForDish(homeCookId, dishId);
      setReviewData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return { getReviewsForDish };
};
