import { Apis } from "libs/apis";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enums";
import { messaging } from "../../../firebase/firebase.config";
import { getToken } from "firebase/messaging";

export default function useLogin() {
  const navigate = useNavigate();

  const login = async (body: any, onSuccess: () => void) => {
    try {
      const res = await Apis.login(body);

      localStorage.setItem("token", res.data.token);
      localStorage.setItem("adminProfile", JSON.stringify(res.data));

      const requestPermission = async () => {
        try {
          console.log("Requesting notification permission...");

          const permission = await Notification.requestPermission();

          if (permission === "granted") {
            console.log("Notification permission granted.");

            const token = await getToken(messaging, {
              vapidKey: process.env.REACT_APP_Fire_BASE_VAPID_KEY,
            });

            if (token) {
              try {
                const userFcmToken = res.data.fcmToken;

                if (userFcmToken !== token) {
                  const body = {
                    fcmToken: token,
                  };

                  await Apis.saveFcm(body);
                } else {
                  console.log(
                    "FCM token matches the one in the database. No update required."
                  );
                }
              } catch (error) {
                console.error(
                  "Failed to update FCM token on the backend",
                  error
                );
              }
            } else {
              console.log("No FCM token available");
            }
          } else {
            console.log("Notification permission denied");
          }
        } catch (error) {
          console.error(
            "Error while requesting permission or getting token:",
            error
          );
        }
      };

      if (res.data.status) {
        console.log("Login successful. Proceeding to request permission...");
        requestPermission();
        onSuccess();
        navigate(siteRoutes.dashboard);
      }
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  return { login };
}
