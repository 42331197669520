import { Apis } from "libs/apis";

export const useOrderDetails = () => {
  const getOrderByUserById = async (
    userId: string,
    orderId: string,
    setOrderDetails: any
  ) => {
    await Apis.getOrderDetailsByUserId(userId, orderId).then((res) => {
        setOrderDetails(res.data.data);
    });
  };
  return { getOrderByUserById };
};