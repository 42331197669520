import styled from "styled-components";

export const Container = styled.form`
width: 100%;
padding: 2rem;
display: flex;
flex-direction: column;
gap: 2rem;


.categories-title{
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1{
        font-weight: 600;
    }

}

td{
    width: 18rem !important;
}

th{
    width: 18rem !important;
}

.th{
    width: 10rem !important;
}
`
