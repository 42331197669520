import React, { useState } from "react";
import { IconInput } from "../../../components/particles/forms/input";
import Swal from "sweetalert2";
import {
  Container,
  InputWrapper,
  Button,
  InnerContainer,
  ContentContainer,
  ProfileContainer,
} from "./style";
import { Profile } from "../profile";
import ProfilePopup from "components/popus/profile-popup";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useUpdatePassword } from "./useHook";

interface Passwords {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const Setting: React.FC = () => {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const { profileModalIsOpen } = useSelector((state: RootState) => state.user);
  const [passwords, setPasswords] = useState<Passwords>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const { updatePassword } = useUpdatePassword();

  const handleUpdatePassword = async () => {
    if (passwordsMatch) {
      const body = {
        currentPassword: passwords.currentPassword,
        newPassword: passwords.newPassword,
      };

      console.log("Sending body to API:", body);

      try {
        await updatePassword(body);
        Swal.fire({
          title: "Success!",
          text: "Your password has been updated successfully.",
          icon: "success",
        });
        localStorage.removeItem("token");
        window.location.reload();
        setPasswords({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "There was an error updating your password.",
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        title: "Oops!",
        text: "Your new password and confirm password do not match.",
        icon: "error",
      });
    }
  };

  const handleChange = (field: keyof Passwords, value: string) => {
    const updatedPasswords = {
      ...passwords,
      [field]: value,
    };
    setPasswords(updatedPasswords);

    if (field === "newPassword" || field === "confirmPassword") {
      setPasswordsMatch(
        updatedPasswords.newPassword === updatedPasswords.confirmPassword
      );
    }
  };

  const allFieldsFilled =
    passwords.currentPassword &&
    passwords.newPassword &&
    passwords.confirmPassword;

  return (
    <ProfileContainer>
      <InnerContainer>
        <h1>Admin profile</h1>
        <ContentContainer>
          <Profile setIsOpen={setIsOpen} />
          <Container>
            <h1>Change Password</h1>
            <div className="input-container">
              <label htmlFor="current-password">Current Password:</label>
              <InputWrapper>
                <IconInput
                  placeholder="current password"
                  onchange={(value: string) =>
                    handleChange("currentPassword", value)
                  }
                  value={passwords.currentPassword}
                />
              </InputWrapper>
            </div>
            <div>
              <div>
                <div className="input-container">
                  <label htmlFor="new-password">New Password</label>
                  <InputWrapper>
                    <IconInput
                      placeholder="New Password"
                      onchange={(value: string) =>
                        handleChange("newPassword", value)
                      }
                      value={passwords.newPassword}
                    />
                  </InputWrapper>
                </div>
                <div className="input-container">
                  <label htmlFor="confirm-password">Confirm Password</label>
                  <InputWrapper>
                    <IconInput
                      placeholder="Confirm Password"
                      onchange={(value: string) =>
                        handleChange("confirmPassword", value)
                      }
                      value={passwords.confirmPassword}
                    />
                  </InputWrapper>
                  {!passwordsMatch && (
                    <p>New password and confirm password do not match</p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Button
                disabled={!allFieldsFilled}
                onClick={handleUpdatePassword}
              >
                Update Password
              </Button>
            </div>
          </Container>
        </ContentContainer>
      </InnerContainer>
      {profileModalIsOpen ? (
        <ProfilePopup modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      ) : (
        ""
      )}
    </ProfileContainer>
  );
};

export default Setting;
