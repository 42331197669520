import { FC } from "react";
import Modal from "react-modal";
import close from "assets/images/close.svg";
import { Container, Status } from "./style";

interface DietaryModelProps {
  setIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  modalData: any; // Add modalData prop here
}

const DietaryChoisePopup: FC<DietaryModelProps> = ({
  modalIsOpen,
  setIsOpen,
  modalData,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      fontSize: "2rem",
      width: "80rem",
      height: "90vh",
      borderRadius: "3rem",
    },
  };
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <button className="close-button" onClick={closeModal}>
        <img src={close} alt="img" />
      </button>
      <Container className=" p-custom-scrollbar-8 custom-scrollbar">
        <div className="title">
          <h1>Dietary Choices</h1>
        </div>
        {modalData?.length > 0 ? (
          modalData.map((item: any, index: number) => (
            <div key={index} className="dietary-choice-container">
              <div className="top-cart">
                {item.questionAndSelection?.map(
                  (questionItem: any, idx: number) => (
                    <div key={idx}>
                      <div className="cart">
                        <h2>{questionItem.question}</h2>
                        <div>
                          <Status>{questionItem.selectedOption}</Status>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="bottom-cart">
                <div className="cart">
                  <h1>
                    This user is following this dietary {item.dietary.name}
                  </h1>

                  <div className="dietaries">
                    {item.ingredients?.map(
                      (ingredientItem: any, idx: number) => (
                        <div key={idx} className="dietary-item">
                          <Status>{ingredientItem.name}</Status>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No dietary choices available.</p>
        )}
      </Container>
    </Modal>
  );
};

export default DietaryChoisePopup;
