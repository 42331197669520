import ErrorBoundary from "components/layout/error-boundary";
import useHttp from "hooks/useHttp";
import { useEffect } from "react";
import AppRoutes from "routes";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "styles";
import { onMessageListener } from "./firebase/firebase.config";
import { toast } from "react-toastify";

function App() {
  const { configureHeaders, configureInterceptors } = useHttp();

  const getScreenInfo = () => {
    const { innerWidth: width, innerHeight: height } = window;
    document.body.style.setProperty("--width", `${width}px`);
    document.body.style.setProperty("--height", `${height}px`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    configureHeaders();
    configureInterceptors();
    document.body.classList.add("p-custom-scrollbar-8");
    window.addEventListener("resize", getScreenInfo);
    window.addEventListener("orientationchange", getScreenInfo);
    getScreenInfo();
  }, []);

  useEffect(() => {
    const listenForMessages = async () => {
      try {
        const payload = await onMessageListener();
        const { title, body } = payload.notification || {};
        if (title && body) {
          toast(
            <div>
              <strong>{title}</strong>
              <p>{body}</p>
            </div>,
            { style: { background: "dodgerblue", color: "white" } }
          );
        }
      } catch (error) {
        console.error("Error handling incoming message:", error);
      }
    };

    listenForMessages();
  }, []);

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register(
        `${process.env.REACT_APP_BASE_URL_LOCAL}firebase-messaging-sw.js`
      )
      .then((registration) => {
        // console.log("Service Worker registered successfully:", registration);
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }

  return (
    <ErrorBoundary>
      <GlobalStyles />
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
