import { Container, CardContainer } from "./style";
import reviews from "assets/images/RatingStar.svg";
import { useEffect, useState } from "react";
import { UseReviewsByHomeCookId } from "./useHook";

interface ReviewsHomeProps {
  homeCookId: string;
}

const ReviewsHome: React.FC<ReviewsHomeProps> = ({ homeCookId }) => {
  const { getReviewsByHomeCookId } = UseReviewsByHomeCookId();
  const [reviewData, setReviewData] = useState<any>([]);

  useEffect(() => {
    getReviewsByHomeCookId(homeCookId, setReviewData);
  }, [homeCookId]);

  return (
    <Container>
      <h1>All Reviews</h1>
      {reviewData.map((review: any) => (
        <CardContainer key={review.id}>
          <div className="user-profile">
            <div className="profile">
              <img
                src={review.User?.image || ""}
                alt="User"
                height={80}
                width={80}
                style={{ borderRadius: "50%" }}
              />
              <div className="user-detail">
                <h1>{review.User?.name || "Unknown User"}</h1>
                <p>
                  {review.rating}
                  {Array(review.rating)
                    .fill(0)
                    .map((_, index) => (
                      <img key={index} src={reviews} alt="Star" />
                    ))}
                </p>
              </div>
            </div>
            <div>
              <p>
                {Math.floor(
                  (new Date().getTime() -
                    new Date(review.createdAt).getTime()) /
                    (1000 * 60 * 60 * 24)
                )}{" "}
                day ago
              </p>
            </div>
          </div>
          <div className="location">
            <h2>Location:</h2>
            <p>{review.User?.location}</p>
          </div>
          <p>{review.review || "No review text provided."}</p>
        </CardContainer>
      ))}
    </Container>
  );
};

export default ReviewsHome;
