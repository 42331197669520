import styled from "styled-components";

export const Container = styled.form`
  h1 {
    font-weight: 600;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  button {
    height: 4rem; /* Adjusted height */
    width: 25rem; /* Adjusted width */
    font-size: 1.5rem; /* Optional: Adjust font size */
    border-radius: 0.5rem; /* Optional: Adjust border radius */
  }
`;
