import styled from "styled-components";

export const Container = styled.form `

.popup-title{
     width: 100%; 
     text-align: center;

     h1{
        font-weight: 600;
     }
}
`