import { Apis } from "libs/apis";

export default function useActivityManagement() {
  const getOrderDetails = async (
    setOrderData: (data: any) => void,
    setOrderPaginationState: Function,
    paginationState: { offset: number; limit: number }
  ) => {
    try {
      const res = await Apis.getAllOrders(
        paginationState.offset,
        paginationState.limit
      );
      setOrderData(res.data.data);
      setOrderPaginationState(res.data.pagination);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };
  const sessionDetails = async (
    setSessionData: (data: any) => void,
    setSessionPaginatioState: Function,
    paginationState: { offset: number; limit: number }
  ) => {
    try {
      const res = await Apis.getSessionDetailsActivity(
        paginationState.offset,
        paginationState.limit
      );
      setSessionData(res.data.data);
      setSessionPaginatioState(res.data.pagination);
    } catch (error) {
      console.error("Error fetching session details:", error);
    }
  };

  const getOrderByUserById = async (
    orderId: string | undefined,
    createdAt: string | undefined,
    retailerName: string | undefined,
    setOrderData: (data: any) => void
  ) => {
    await Apis.getOrderActivity(orderId, createdAt, retailerName).then(
      (res) => {
        setOrderData(res.data.data);
      }
    );
  };

  const getSessionByUserById = async (
    sessionId: string | undefined,
    createdAt: string | undefined,
    setSessionData: (data: any) => void,
    homeCookName: string | undefined,
    status: string | undefined
  ) => {
    await Apis.getSessionActivivty(
      sessionId,
      createdAt,
      homeCookName,
      status
    ).then((res) => {
      setSessionData(res.data.data);
    });
  };

  return {
    getOrderDetails,
    sessionDetails,
    getOrderByUserById,
    getSessionByUserById,
  };
}
