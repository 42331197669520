import { Container, CardContainer } from "./style";
import reviews from "assets/images/RatingStar.svg";
import { useDishReview } from "./useHook";
import { useEffect, useState } from "react";

interface ReceipeReviewsProps {
  dishData: any;
}

const Reviews: React.FC<ReceipeReviewsProps> = ({ dishData }) => {
  const [reviewData, setReviewData] = useState<any>([]);
  const { getReviewsForDish } = useDishReview();

  useEffect(() => {
    if (dishData?.[0]?.homeCookId && dishData?.[0]?.dishId) {
      getReviewsForDish(
        dishData[0].homeCookId,
        dishData[0].dishId,
        setReviewData
      );
    }
  }, [dishData]);

  if (!reviewData.length) {
    return (
      <Container>
        <h1>All reviews</h1>
        <p>No reviews yet</p>
      </Container>
    );
  }

  return (
    <Container>
      <h1>All reviews</h1>
      <CardContainer>
        {reviewData.map((review: any, index: number) => (
          <div key={index} className="user-profile">
            <div className="profile">
              <img
                src={"https://picsum.photos/300"}
                alt="img"
                height={80}
                width={80}
                style={{ borderRadius: "50%" }}
              />
              <div className="user-detail">
                <h1>{"User Name"}</h1>
                <p>
                  4.0 <img src={reviews} /> <img src={reviews} />{" "}
                  <img src={reviews} /> <img src={reviews} />{" "}
                  <img src={reviews} />
                </p>
              </div>
            </div>
            <div>
              <p>3 day ago</p>
            </div>
            <div className="location">
              <h2>Location :</h2>
              <p>Pakistan</p>
            </div>
            <p>{review.comment}</p>
          </div>
        ))}
      </CardContainer>
    </Container>
  );
};

export default Reviews;
