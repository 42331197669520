import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { dispatch } from "store";

import {
  Container,
  CardContainer,
  TabeContainer,
  TabeCart,
  StatusTabeContainer,
  Status,
} from "./styled";

import {
  setChefActiveButton,
  setChefStatusActiveButton,
  setModalOpened,
} from "store/user-slice";

import Pagination from "components/particles/pagination/pagination";
import NoDataFound from "components/particles/no-data-found";
import Reviews from "../view-receipe/reviews";
import { tableData } from "utils/helpers/dummyData";
import Button from "components/particles/primary-button";

import user from "assets/images/chef-user.svg";
import chefLocation from "assets/images/chef-location.svg";
import bag from "assets/images/chef-bag.svg";
import lang from "assets/images/language.svg";
import demote from "assets/images/demote.png";

import sessionDarkIcon from "assets/images/session-dark-icon.svg";
import sessionIcon from "assets/images/session-white-icon.svg";
import dishesIcon from "assets/images/dishes-icon.svg";
import dishesDarkIcon from "assets/images/dishes-dark-icon.svg";
import reviewsIcon from "assets/images/reviews-white-icon.svg";

import reviewsDarkIcon from "assets/images/reviews-dark-icon.svg";
import premium from "assets/images/premium.svg";
import rating from "assets/images/RatingStar.svg";
import view from "assets/images/view-icon.svg";
import { siteRoutes } from "utils/helpers/enums/routes.enums";

import StatusTabe from "components/particles/status-tabe";
import { useHomeChefById } from "./useHook";
import { set, useForm } from "react-hook-form";
import {
  HomeChefViewReceipeSearchDTO,
  HomeChefViewSessionSearchDTO,
} from "utils/helpers/models/homeChef.dto";
import ReviewsHome from "./reviews";
import PromoteHomeCookModal from "components/popus/PromotoHomeCook";

const HomeChefView: React.FC = () => {
  const location = useLocation();
  const { state } = location;
  const { isLoading } = useSelector((state: any) => state.user);
  const navigate = useNavigate();

  const { chefActiveButton, chefStatusActiveButton } = useSelector(
    (state: any) => state.user
  );
  const { getHomeChefById, getDishesById, getSessionById, getReviewById } =
    useHomeChefById();
  const [data, setData] = useState<any[]>();
  const [dishData, setDishData] = useState<any[]>();
  const [sessionData, setSessionData] = useState<any[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action, setAction] = useState<"promote" | "demote" | null>(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [sessionPagintaionState, setSessionPaginationState] = useState<any>();
  const [dishesPagintaionState, setDishesPaginationState] = useState<any>();
  console.log(sessionPagintaionState, "sessionPagintaionState");
  console.log(dishesPagintaionState, "dishesPagintaionState");
  const { register, handleSubmit } = useForm<
    HomeChefViewReceipeSearchDTO | HomeChefViewSessionSearchDTO
  >();

  const handleViewPage = (sessionId: string) => {
    navigate(siteRoutes.homeChefViewDetail, { state: { sessionId } });
  };

  const limit = 10;
  const offset = 1;

  const upgradePremium = () => {
    setAction("promote");
    setIsModalOpen(true);
  };

  const demoteHomeCook = () => {
    setAction("demote");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setAction(null);
    setRefreshKey((prev) => prev + 1);
  };

  const fetchNextRecords = (offset: number) => {
    if (chefActiveButton === "Session") {
      getSessionById(
        state.id,
        chefStatusActiveButton !== "All" ? chefStatusActiveButton : "",
        setSessionData,
        "",
        "",
        "",
        setSessionPaginationState,
        limit,
        offset
      );
    } else if (chefActiveButton === "Recipes") {
      getDishesById(
        state.id,
        "",
        "",
        "",
        setDishData,
        setDishesPaginationState,
        limit,
        offset
      );
    }
  };

  useEffect(() => {
    if (state?.id) {
      getHomeChefById(state.id, setData, offset, limit);
    }
  }, [state?.id, refreshKey]);

  useEffect(() => {
    if (state?.id) {
      const status = state?.status;
      const dishName = state?.dishName;
      const createdAt = state?.createdAt;
      const homeCookName = state?.homeCookName;
      getSessionById(
        state.id,
        status,
        setSessionData,
        createdAt,
        dishName,
        homeCookName,
        setSessionPaginationState,
        limit,
        offset
      );
    }
  }, [state?.id]);

  function onStatusChange(type: string, id: string, status: string) {
    if (type === "Session") {
      getSessionById(
        state.id,
        status,
        setSessionData,
        "",
        "",
        "",
        setSessionPaginationState,
        limit,
        offset
      );
    }
  }

  useEffect(() => {
    if (state?.id) {
      const dishName = state?.dishName;
      const userDietaryChoice = state?.userDietaryChoice;
      const userState = state?.userState;
      getDishesById(
        state.id,
        dishName,
        userDietaryChoice,
        userState,
        setDishData,
        setDishesPaginationState
      );
    }
  }, [state?.id]);

  const search = (
    dataa: HomeChefViewReceipeSearchDTO | HomeChefViewSessionSearchDTO
  ) => {
    const statusFilter =
      chefStatusActiveButton !== "All" ? chefStatusActiveButton : dataa.status;

    if (chefActiveButton === "Session" && "name" in dataa) {
      getSessionById(
        state.id,
        statusFilter || undefined,
        setSessionData,
        dataa.createdAt,
        dataa.name,
        "",
        setSessionPaginationState,
        limit,
        offset
      );
    } else if (chefActiveButton === "Recipes") {
      getDishesById(
        state.id,
        dataa.dishName,
        dataa.dietaries,
        dataa.state,
        setDishData,
        setDishesPaginationState
      );
    }
  };

  return (
    <Container>
      <div className="title">
        <h1>Home Cook</h1>
        <div>
          {data?.[0]?.premiumHomeCook ? (
            <button onClick={demoteHomeCook}>
              <img src={demote} alt="img" />
              <p>Demote HomeCook</p>
            </button>
          ) : (
            <button onClick={upgradePremium}>
              <img src={premium} alt="img" />
              <p>Upgrade as Premium</p>
            </button>
          )}
        </div>
      </div>

      <CardContainer>
        {data?.map((item, index) => (
          <Fragment key={index}>
            <div className="user-profile">
              <img src={item.image} alt="img" />
              <div className="user-detail">
                <h1>{item.name || "User Name"}</h1>
                <p>
                  {item.premiumHomeCook
                    ? "Premium Home Cook"
                    : "Standard Home Cook"}
                </p>
              </div>
            </div>
            <div className="user-info">
              <div className="info-row">
                <img src={bag} alt="calendar" />
                <p>{item.profession}</p>
              </div>
              <div className="info-row">
                <img src={lang} alt="weightScale" />
                <p>{item.language?.join(", ") || "English"}</p>
              </div>

              <div className="info-row">
                <img src={chefLocation} alt="weightScale" />
                <p>{item.address || "English"}</p>
              </div>
              <div className="info-row">
                <img src={user} alt="height" />
                <p>{item.bio || "Passionate about food and life 🥘🍲🍝🍱"}</p>
              </div>
            </div>
            <div className="info-detail">
              <div>
                <p>Dishes</p>
                <h1>{item.totalDishes || 4}</h1>
              </div>
              <div>
                <p>Following</p>
                <h1>{item.totalFollowers}</h1>
              </div>
              <div>
                <p>Rating</p>
                <h1>{item.homeCookAverageRating || "2.22k"}</h1>
              </div>
            </div>
          </Fragment>
        ))}
      </CardContainer>

      <TabeContainer>
        <TabeCart
          active={chefActiveButton === "Session"}
          onClick={() => dispatch(setChefActiveButton("Session"))}
        >
          <div className="categories-tab-img">
            <img
              src={
                chefActiveButton === "Session" ? sessionIcon : sessionDarkIcon
              }
              alt="img"
            />
          </div>
          <h1>Session</h1>
        </TabeCart>
        <TabeCart
          active={chefActiveButton === "Recipes"}
          onClick={() => dispatch(setChefActiveButton("Recipes"))}
        >
          <div className="categories-tab-img">
            <img
              src={chefActiveButton === "Recipes" ? dishesIcon : dishesDarkIcon}
              alt="img"
            />
          </div>
          <h1>Dishes</h1>
        </TabeCart>
        <TabeCart
          active={chefActiveButton === "Reviews"}
          onClick={() => dispatch(setChefActiveButton("Reviews"))}
        >
          <div className="categories-tab-img">
            <img
              src={
                chefActiveButton === "Reviews" ? reviewsIcon : reviewsDarkIcon
              }
              alt="img"
            />
          </div>
          <h1>Reviews</h1>
        </TabeCart>
      </TabeContainer>

      {chefActiveButton === "Session" && (
        // <StatusTabeContainer>
        //   {statusTabs.map((tab: string, index: number) => {
        //     return (
        //       <StatusTabe
        //         key={index}
        //         active={chefStatusActiveButton === tab}
        //         onclick={() => {
        //           dispatch(setChefStatusActiveButton(tab));

        //           onStatusChange("Session", state.id, "");
        //         }}
        //         title={tab}
        //       />
        //     );
        //   })}
        // </StatusTabeContainer>

        <StatusTabeContainer>
          {chefActiveButton === "Session" && (
            <>
              <StatusTabe
                active={chefStatusActiveButton === "All"}
                onclick={() => {
                  onStatusChange("Session", state.id, "");
                  dispatch(setChefStatusActiveButton("All"));
                }}
                title={"All"}
              />
              <StatusTabe
                active={chefStatusActiveButton === "active"}
                onclick={() => {
                  onStatusChange("Session", state.id, "active");
                  dispatch(setChefStatusActiveButton("active"));
                }}
                title={"Active"}
              />
              <StatusTabe
                active={chefStatusActiveButton === "completed"}
                onclick={() => {
                  onStatusChange("Session", state.id, "completed");
                  dispatch(setChefStatusActiveButton("completed"));
                }}
                title={"Completed"}
              />
              <StatusTabe
                active={chefStatusActiveButton === "incoming"}
                onclick={() => {
                  onStatusChange("Session", state.id, "incoming");
                  dispatch(setChefStatusActiveButton("incoming"));
                }}
                title={"Incoming"}
              />
              <StatusTabe
                active={chefStatusActiveButton === "rejected"}
                onclick={() => {
                  onStatusChange("Session", state.id, "rejected");
                  dispatch(setChefStatusActiveButton("rejected"));
                }}
                title={"Rejected"}
              />
              <StatusTabe
                active={chefStatusActiveButton === "inProcess"}
                onclick={() => {
                  onStatusChange("Session", state.id, "inProcess");
                  dispatch(setChefStatusActiveButton("inProcess"));
                }}
                title={"Paid"}
              />
              <StatusTabe
                active={chefStatusActiveButton === "expired"}
                onclick={() => {
                  onStatusChange("Session", state.id, "expired");
                  dispatch(setChefStatusActiveButton("expired"));
                }}
                title={"Expired"}
              />
              <StatusTabe
                active={chefStatusActiveButton === "readyToPay"}
                onclick={() => {
                  onStatusChange(state.id, "Session", "readyToPay");
                  dispatch(setChefStatusActiveButton("readyToPay"));
                }}
                title={"Ready to Pay"}
              />
            </>
          )}
        </StatusTabeContainer>
      )}

      {chefActiveButton === "Session" ? (
        <div className="search-container">
          <div className="input-fields">
            <input
              type="text"
              placeholder="Search by Dish Name"
              {...register("name")}
            />
            {/* <input type="date" name="date" id="date" /> */}
            <div className="custom-date-input-container">
              <input
                type="date"
                className="custom-date-input"
                placeholder="Search by Date"
                {...register("createdAt")}
              />
            </div>
            <input
              type="text"
              placeholder="Search by Users"
              {...register("users")}
            />
          </div>
          <div className="button-fields">
            <Button
              title="Search"
              width="16rem"
              fill="true"
              onclick={handleSubmit(search)}
            />
            <Button
              title="Reset"
              width="16rem"
              fill="true"
              backgroundcolor="var(--reset-button)"
              onclick={handleSubmit(search)}
            />
          </div>
        </div>
      ) : chefActiveButton === "Recipes" ? (
        <div className="search-container">
          <div className="input-fields">
            <input
              type="text"
              placeholder="Search By Dish Name"
              {...register("dishName")}
            />
            <input
              type="text"
              placeholder="Search By Dietary Name"
              {...register("dietaries")}
            />
            {/* <select id="dietaries" {...register("dietaries")}>
              {options.map((option, index) => {
                return (
                  <option value={option} key={index}>
                    {option}
                  </option>
                );
              })}
            </select> */}
            <input
              type="text"
              placeholder="Search By State"
              {...register("state")}
            />
          </div>
          <div className="button-fields">
            <Button
              title="Search"
              width="16rem"
              fill="true"
              onclick={handleSubmit(search)}
            />
            <Button
              title="Reset"
              width="16rem"
              fill="true"
              backgroundcolor="var(--reset-button)"
              onclick={handleSubmit(search)}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="data-table">
        {chefActiveButton === "Session" && (
          <div className="data-table">
            <table>
              <thead>
                <tr>
                  <th>Dish</th>
                  <th>Date</th>
                  <th className="th">Amount</th>
                  <th>Username</th>
                  <th className="th">Duration</th>
                  {chefStatusActiveButton === "All" ||
                  chefStatusActiveButton === "Complete" ? (
                    <th className="th">Rating</th>
                  ) : null}
                  {chefStatusActiveButton === "All" ? (
                    <th className="th">Status</th>
                  ) : null}
                  <th className="th">Actions</th>
                </tr>
              </thead>
              <tbody>
                {sessionData && sessionData.length > 0 ? (
                  sessionData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={
                            item.dish.coverImage[0] ||
                            "https://picsum.photos/200"
                          }
                          alt="img"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />{" "}
                        {item.dish.dishName}
                      </td>
                      <td>{item.sessionDetails.placedOn.split("T")[0]}</td>
                      <td className="th">
                        {item.sessionDetails.sessionAmount}
                      </td>
                      <td>{item.user.userName}</td>
                      <td className="th">
                        {item.sessionDetails.sessionDuration}
                      </td>
                      {chefStatusActiveButton === "All" ||
                      chefStatusActiveButton === "Complete" ? (
                        <td className="th">
                          {/* <img src={rating} alt="img" />{" "} */}
                          {item.sessionDetails.sessionRating || 0}
                        </td>
                      ) : null}
                      {chefStatusActiveButton === "All" ? (
                        <td className="th">
                          <Status active={item.status}>
                            {item.sessionDetails.sessionStatus
                              .charAt(0)
                              .toUpperCase() +
                              item.sessionDetails.sessionStatus.slice(1)}
                          </Status>
                        </td>
                      ) : null}
                      <td className="actions th">
                        <div
                          className="view-container"
                          onClick={() => handleViewPage(item.sessionId)}
                        >
                          <img src={view} alt="img" />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoDataFound isShow={!isLoading} />
                )}
              </tbody>
            </table>
          </div>
        )}
        <div className="data-table">
          {chefActiveButton === "Recipes" && (
            <table>
              <thead>
                <tr>
                  <th>Dish</th>
                  <th className="th">Serving size</th>
                  <th className="th">Prep Time</th>
                  <th>Dietary</th>
                  <th className="th">State</th>
                  <th className="th">Ingredients</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {dishData && dishData.length > 0 ? (
                  dishData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={
                            item.coverImage[0] || "https://picsum.photos/200"
                          }
                          alt="Dish"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />{" "}
                        {item.name}
                      </td>
                      <td className="th">{item.serving}</td>
                      <td className="th">{item.preparationTime}</td>
                      <td>
                        {item.dietaries
                          .slice(0, 2)
                          .map((dietary: any) => dietary.dietaryName)
                          .join(", ")}{" "}
                        {item.dietaries.length > 2 && " ..."}{" "}
                      </td>
                      <td className="th">{item.state}</td>
                      <td className="th">{item.ingredientCount}</td>
                      <td className="actions">
                        <Button
                          title="View Dish"
                          width="16rem"
                          fill="true"
                          backgroundcolor="var(--reset-button)"
                          onclick={() =>
                            navigate(siteRoutes.homeChefViewReceipe, {
                              state: { dishId: item.dishId },
                            })
                          }
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoDataFound isShow={!isLoading} />
                )}
              </tbody>
            </table>
          )}
        </div>

        {chefActiveButton === "Reviews" && (
          <ReviewsHome homeCookId={state?.id} />
        )}
      </div>
      {chefActiveButton !== "Reviews" && (
        <Fragment>
          <Pagination
            totalRecords={
              chefActiveButton === "Session"
                ? sessionPagintaionState?.totalSessions
                : dishesPagintaionState?.totalDishes
            }
            perPage={limit}
            handleFetchNextRecords={fetchNextRecords}
          />
        </Fragment>
      )}
      <PromoteHomeCookModal
        isOpen={isModalOpen}
        action={action}
        onClose={closeModal}
        homeCookId={state?.id}
      />
    </Container>
  );
};

export default HomeChefView;
