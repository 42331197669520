import { ChangeEvent, FC, useEffect, useState } from "react";
import Modal from "react-modal";
import close from "assets/images/close.svg";
import logo from "assets/images/modal-logo.svg";
import Button from "components/particles/primary-button";
import { RetailerDTO } from "utils/helpers/models/retailer.dto";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { Container } from "./style";
import img from "assets/images/input-img-icon.svg";
import { useSelector } from "react-redux";
import { dispatch } from "store";
import { setModalClosed } from "store/user-slice";
import useRetailers from "containers/retailer/listing/useHook";
import Swal from "sweetalert2";
import cam from "assets/images/upload-cam.svg";
import upload from "assets/images/upload-arrow.svg";
import deleteIcon from "assets/images/delete.svg";

interface RetailerModelProps {
  refreshRetailers: () => void;
}

const RetailerPopup: FC<RetailerModelProps> = ({ refreshRetailers }) => {
  const [selectedCoverImage, setSelectedCoverImage] = useState<string | null>(
    null
  );
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { addRetailerModalIsOpen } = useSelector((state: any) => state.user);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<RetailerDTO>();

  const { postRetailer } = useRetailers();

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedImage(URL.createObjectURL(file));
      setValue("image", file);
    }
  };
  const handleCoverImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const files = event.target.files[0];
      setSelectedCoverImage(URL.createObjectURL(files));
      setValue("coverImage", files);
    }
  };

  const onSubmit = async (data: RetailerDTO) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value) formData.append(key, value as any);
    });

    try {
      await postRetailer(formData);
      dispatch(setModalClosed("Retailer"));
      refreshRetailers();
      Swal.fire("Success", "Retailer added successfully", "success");
      reset();
      setSelectedCoverImage(null);
      setSelectedImage(null);
      setValue("image", null);
      setValue("coverImage", null);
    } catch (error) {
      console.error(error);
      Swal.fire("Error", "Failed to add retailer", "error");
    }
  };

  const closeModal = () => {
    dispatch(setModalClosed("Retailer"));
    setSelectedImage(null);
    setSelectedCoverImage(null);
    setValue("image", null);
    setValue("coverImage", null);
  };

  const closeImg = () => {
    setSelectedImage(null);
    setValue("image", null);
  };
  const closeCoverImg = () => {
    setSelectedCoverImage(null);
    setValue("coverImage", null);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      fontSize: "2rem",
      width: "68.5rem",
      // height: "80vh",
      borderRadius: "3rem",
      padding: "0",
    },
  };

  return (
    <Modal
      isOpen={addRetailerModalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Add a New Retailer"
    >
      <Container onSubmit={handleSubmit(onSubmit)}>
        <div className="model-header" style={{ position: "relative" }}>
          <div className="model-title">
            <h1 className="tag-line-banner">Add a New Retailer</h1>
            <button className="close-button" onClick={closeModal}>
              <img src={close} alt="Close" />
            </button>
          </div>
          {/* / cover Img / */}

          {selectedCoverImage ? (
            <>
              <img
                style={{ width: "100%", height: "78%", objectFit: "cover" }}
                src={selectedCoverImage}
                alt="Selected"
              />
              <img
                className="close-icon"
                style={{ top: "110px", right: "30px" }}
                src={deleteIcon}
                alt="Remove"
                onClick={closeCoverImg}
              />
            </>
          ) : (
            <>
              {/* <label htmlFor="image-cover-input">
                      <img src={img} alt="Upload" className="image-input" />
                    </label> */}
              <label
                htmlFor="image-cover-input"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  paddingTop: "50px",
                  paddingRight: "20px",
                  gap: "1rem",
                }}
              >
                <img src={upload} className="upload-icon" alt="" />
                <p>Upload Cover</p>
              </label>
              <input
                id="image-cover-input"
                type="file"
                accept="image/*"
                className="hidden-input"
                {...register("coverImage")}
                onChange={handleCoverImageChange}
              />
            </>
          )}

          {/* / Logo / */}
          <div className="logo-main" style={{ position: "unset" }}>
            {selectedImage ? (
              <div
                className="modal-logo"
                style={{ position: "absolute", top: "63px" }}
              >
                <img
                  src={selectedImage}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </div>
            ) : (
              <div
                className="modal-logo"
                style={{ position: "absolute", top: "63px" }}
              >
                <img src={logo} alt="" />
              </div>
            )}

<label htmlFor="image-input">
  {selectedImage ? (
    <img
      src={selectedImage}
      className="uploaded-image"
      alt="Selected"
      style={{ top: "140px" }}
    />
  ) : (
    <img
      src={cam}
      className="upload-cam"
      alt=""
      style={{ top: "140px", cursor: "pointer" }}
    />
  )}
</label>
{selectedImage ? (
  <>
    <img
      className="uploaded-image"
      src={selectedImage}
      alt="Selected"
    />
    <img
      className="close-icon"
      src={deleteIcon}
      alt="Remove"
      onClick={closeImg}
      style={{ top: "150px", left: "55%", cursor: "pointer" , backgroundColor: "red", borderRadius: "50%", padding: "5px", width: "30px", height: "30px" }}
    />
  </>
) : (
  <>
    <label htmlFor="image-input">
      <img src={img} alt="Upload" className="image-input" />
    </label>
    <input
      id="image-input"
      type="file"
      accept="image/*"
      className="hidden-input"
      {...register("image")}
      onChange={handleImageChange}
    />
  </>
)}

          </div>
        </div>

        <div className="model-container">
          {/* <div>
            <h1>Add a New Retailer</h1>
          </div> */}
          <div className="add-retailer-container">
            {/* <div className="image-container">
              <div className="image-input-wrapper">
                {selectedCoverImage ? (
                  <>
                    <img
                      className="uploaded-image"
                      src={selectedCoverImage}
                      alt="Selected"
                    />
                    <img
                      className="close-icon"
                      src={close}
                      alt="Remove"
                      onClick={closeCoverImg}
                    />
                  </>
                ) : (
                  <>
                    <label htmlFor="image-cover-input">
                      <img src={img} alt="Upload" className="image-input" />
                    </label>
                    <input
                      id="image-cover-input"
                      type="file"
                      accept="image/*"
                      className="hidden-input"
                      {...register("coverImage")}
                      onChange={handleCoverImageChange}
                    />
                  </>
                )}
              </div>
              <p>{getValues("coverImage.name")}</p>
            </div> */}
            {/* <div className="image-container">
              <div className="image-input-wrapper">
                {selectedImage ? (
                  <>
                    <img
                      className="uploaded-image"
                      src={selectedImage}
                      alt="Selected"
                    />
                    <img
                      className="close-icon"
                      src={close}
                      alt="Remove"
                      onClick={closeImg}
                    />
                  </>
                ) : (
                  <>
                    <label htmlFor="image-input">
                      <img src={img} alt="Upload" className="image-input" />
                    </label>
                    <input
                      id="image-input"
                      type="file"
                      accept="image/*"
                      className="hidden-input"
                      {...register("image")}
                      onChange={handleImageChange}
                    />
                  </>
                )}
              </div>
              <p>{getValues("image.name")}</p>
            </div> */}
            <div className="retailer-input">
              <input
                type="text"
                placeholder="Legal First Name"
                {...register("firstName", { required: true })}
              />
              <FormErrorMessage error={errors.firstName} />
            </div>
            <div className="retailer-input">
              <input
                type="text"
                placeholder="Legal Last Name"
                {...register("lastName", { required: true })}
              />
              <FormErrorMessage error={errors.lastName} />
            </div>
            <div className="retailer-input">
              <input
                type="text"
                placeholder="Title (Role in Store)"
                {...register("title", { required: true })}
              />
              <FormErrorMessage error={errors.title} />
            </div>
            <div className="retailer-input">
              <input
                type="text"
                placeholder="Email Address"
                {...register("email", { required: true })}
              />
              <FormErrorMessage error={errors.email} />
            </div>
            <div className="retailer-input">
              <input
                type="text"
                placeholder="Company Name"
                {...register("companyName")}
              />
            </div>
            <div className="retailer-input">
              <input
                type="text"
                placeholder="Phone"
                {...register("phone", { required: true })}
              />
            </div>
            <div className="retailer-input">
              <input
                type="text"
                placeholder="Website / Social media URL"
                {...register("websiteUrl")}
              />
            </div>
            <div className="retailer-input">
              <input
                type="text"
                placeholder="SSN / EIN"
                {...register("SSNEIN", { required: true })}
              />
              <FormErrorMessage error={errors.SSNEIN} />
            </div>
            <div className="retailer-input">
              <input
                type="text"
                placeholder="Opening Time"
                {...register("openingTime", { required: true })}
              />
              <FormErrorMessage error={errors.openingTime} />
            </div>
            <div className="retailer-input">
              <input
                type="text"
                placeholder="Closing Time"
                {...register("closingTime", { required: true })}
              />
              <FormErrorMessage error={errors.closingTime} />
            </div>
            <input
              type="text"
              placeholder="Store Address"
              style={{ width: "100%" }}
              {...register("physicalAddress", { required: true })}
            />
            <FormErrorMessage error={errors.physicalAddress} />
            <div className="retailer-input">
              <input
                type="text"
                placeholder="state"
                {...register("state", { required: true })}
              />
            </div>
            <div className="retailer-input">
              <input
                type="text"
                placeholder="City"
                {...register("city", { required: true })}
              />
            </div>
            <div className="retailer-input">
              <input
                type="text"
                placeholder="Zip Code"
                {...register("zipCode", { required: true })}
              />
              <FormErrorMessage error={errors.zipCode} />
            </div>
            <div className="retailer-input">
              <input
                type="text"
                placeholder="Google Map Location URL"
                {...register("googleLocationUrl", { required: true })}
              />
              <FormErrorMessage error={errors.googleLocationUrl} />
            </div>
          </div>

          <div className="add-button">
            <Button
              title="Add"
              width="51.2rem"
              backgroundcolor="var(--primary)"
              fill="true"
            />
          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default RetailerPopup;
