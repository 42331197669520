import { Apis } from "libs/apis";
import { dispatch } from "store";
import { setModalClosed, stopLoading } from "store/user-slice";
import Swal from "sweetalert2";

interface Filters {
  name?: string;
  orderId?: string;
  date?: string;
}

export const useRetailersById = () => {
  const getRetailersById = async (id: string, setDataByID: Function) => {
    await Apis.getRetailersById(id)
      .then((res) => {
        setDataByID(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOrderByRetailerById = async (
    setOrderRetailerID: (data: any) => void,
    retailerId: string,
    status?: string,
    setOrderPaginationState?: Function,
    offset: number = 0,
    limit: number = 10
  ) => {
    const queryParams = `retailerId=${retailerId}&offset=${offset}&limit=${limit}${
      status ? `&status=${status}` : ""
    }`;
    try {
      const res = await Apis.getOrderByRetailerId(queryParams);
      setOrderRetailerID(res.data.data);
      if (setOrderPaginationState) {
        setOrderPaginationState(res.data.pagination);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  return { getRetailersById, getOrderByRetailerById };
};
