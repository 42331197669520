import { Apis } from "libs/apis";

export const UseReviewsByHomeCookId = () => {
  const getReviewsByHomeCookId = async (
    homeCookId: string,
    setReviewData: any
  ) => {
    await Apis.getReviewById(homeCookId)
      .then((res) => {
        setReviewData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return { getReviewsByHomeCookId };
};
