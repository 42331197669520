import { useEffect, useState, useRef, useCallback } from "react";
import { Cart, Container, InnerContainer } from "./style";
import { useNotifications } from "./useHook";

interface NotificationData {
  userId: string;
  userName: string;
  userEmail: string;
  userMobileNumber: string;
}

interface Notification {
  id: number;
  title: string;
  body: string;
  data: NotificationData;
  sentAt: string;
}

interface NotificationsResponse {
  [key: string]: Notification[];
}

const Notification = () => {
  const [notifications, setNotifications] = useState<NotificationsResponse>({});
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef<IntersectionObserver | null>(null);

  const { getNotifications } = useNotifications();

  const loadMoreNotifications = useCallback(() => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);

    getNotifications(
      (newNotifications: NotificationsResponse) => {
        if (Object.keys(newNotifications).length === 0) {
          setHasMore(false);
        } else {
          setNotifications((prev) => ({
            ...prev,
            ...newNotifications,
          }));
          setOffset((prev) => prev + 10);
        }
        setIsLoading(false);
      },
      offset,
      10
    );
  }, [isLoading, hasMore, offset, getNotifications]);

  useEffect(() => {
    loadMoreNotifications();
  }, []);

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreNotifications();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, loadMoreNotifications]
  );

  return (
    <Container>
      <h1>Notifications</h1>

      {Object.keys(notifications).map((date: any, index) => (
        <InnerContainer key={date}>
          <div className="time">
            <span>{date}</span>
          </div>

          {notifications[date].map((notif: any, idx: number) => (
            <Cart
              key={notif.id}
              ref={
                index === Object.keys(notifications).length - 1 &&
                idx === notifications[date].length - 1
                  ? lastElementRef
                  : null
              }
            >
              <div className="left">
                <h1>{notif.title}</h1>
                <span>{notif.data.userName}</span>
              </div>
              <div className="right">
                <p>
                  {new Date(notif.sentAt).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </p>
              </div>
            </Cart>
          ))}
        </InnerContainer>
      ))}

      {isLoading && <p>Loading more notifications...</p>}
      {!hasMore && <p>No more notifications</p>}
    </Container>
  );
};

export default Notification;
