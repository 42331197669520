import styled from "styled-components";

export const Container = styled.form`

h1{
    font-weight: 600;
}


`

