import { FC, Fragment, useEffect, useState } from "react";
import Pagination from "../../../components/particles/pagination/pagination";
import { Container } from "./style";
import RetailerPopup from "components/popus/retailer-popup";

import Button from "components/particles/primary-button";
import { tableData } from "utils/helpers/dummyData";
import NoDataFound from "components/particles/no-data-found";
import { useNavigate } from "react-router-dom";

import view from "assets/images/view-icon.svg";
import { siteRoutes } from "utils/helpers/enums/routes.enums";
import { useForm } from "react-hook-form";
import { RetailerListSearchDTO } from "utils/helpers/models/retailer.dto";
import { dispatch } from "store";
import { setModalOpened } from "store/user-slice";
import { useSelector } from "react-redux";
import useRetailers from "./useHook";

const Retailer: FC = () => {
  const [retailersData, setRetailersData] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  // const [usersData, setUsersData] = useState<any[]>([]);
  const [retailerPaginationState, setRetailerPaginationState] = useState<any>();
  const { isLoading } = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    reset: formReset,
  } = useForm<RetailerListSearchDTO>();
  const search = (retailersData: RetailerListSearchDTO) => {
    let statusValue;
    if (retailersData.status === "Active") {
      statusValue = true;
    } else if (retailersData.status === "Inactive") {
      statusValue = false;
    } else {
      statusValue = "";
    }

    getRetailers(
      setRetailersData,
      {
        email: retailersData.email || "",
        mobileNumber: retailersData.mobileNumber || "",
        companyName: retailersData.companyName || "",
        status: statusValue,
        limit,
        offset: 0,
      },
      setRetailerPaginationState
    );
  };

  const reset = () => {
    setValue("email", "");
    setValue("mobileNumber", "");
    setValue("companyName", "");
    setValue("status", "");
    formReset();

    getRetailers(
      setRetailersData,
      { limit, offset: 0 },
      setRetailerPaginationState
    );
  };

  const addRetailer = () => {
    dispatch(setModalOpened("Retailer"));
  };

  const { postRetailer, getRetailers, updateUserStatusById } = useRetailers();

  useEffect(() => {
    getRetailers(setRetailersData, {}, setRetailerPaginationState);
  }, []);

  const fetchRetailers = () => {
    getRetailers(setRetailersData, {}, setRetailerPaginationState);
  };

  const handleViewPage = (id: string) => {
    navigate(siteRoutes.retailerView, { state: { id } });
  };

  const limit = 10;
  const fetchNextRecords = (offset: number) => {
    getRetailers(
      setRetailersData,
      { limit, offset },
      setRetailerPaginationState
    );
  };

  const toggleUserStatus = async (userId: string, status: boolean) => {
    const updatedRetailers = retailersData.map((retailer) =>
      retailer.id === userId ? { ...retailer, status } : retailer
    );
    setRetailersData(updatedRetailers);

    try {
      await updateUserStatusById(userId, status);
    } catch (err) {
      const revertedRetailers = retailersData.map((retailer) =>
        retailer.id === userId ? { ...retailer, status: !status } : retailer
      );
      setRetailersData(revertedRetailers);
    }
  };

  const columns: any = [
    {
      name: "Name",
      class: "",
    },
    {
      name: "Email",
      class: "",
    },
    {
      name: "Phone Number",
      class: "",
    },
    {
      name: "Company Name",
      class: "",
    },
    {
      name: "Address",
      class: "th",
    },
    {
      name: "Status",
      class: "th",
    },
    {
      name: "Actions",
      class: "th",
    },
  ];

  const options = ["", "Active", "Inactive"];

  return (
    <Container>
      <div className="title">
        <h1>Retailers list</h1>
        <Button
          title="Add New Retailer"
          onclick={addRetailer}
          width="16.5rem"
          fill="true"
          backgroundcolor="var(--reset-button)"
        />
      </div>
      <div className="search-container">
        <div className="input-fields">
          <input
            type="text"
            placeholder="Search By Email"
            {...register("email")}
          />
          <input
            type="text"
            placeholder="Search By Phone Number"
            {...register("mobileNumber")}
          />
          <input
            type="text"
            placeholder="Search By Company"
            {...register("companyName")}
          />
          {/* <select {...register("status")} id="status">
            {options.map((option, index) => {
              return (
                <option value={option} key={index}>
                  {option}
                </option>
              );
            })}
          </select> */}

          <select id="status" {...register("status")}>
            <option value="">Status</option>
            {options.slice(1).map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="button-fields">
          <Button
            title="Search"
            width="16rem"
            fill="true"
            onclick={handleSubmit(search)}
          />
          <Button
            title="Reset"
            width="16rem"
            fill="true"
            backgroundcolor="var(--reset-button)"
            onclick={handleSubmit(reset)}
          />
        </div>
        {/* <Input /> */}
      </div>
      <div className="data-table">
        <table>
          <thead>
            <tr>
              {columns.map((column: any, index: number) => {
                return (
                  <th className={column.class} key={index}>
                    {column.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {retailersData?.length > 0 ? (
              retailersData?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <img src={item.image} className="img" alt="img" />
                    {item.firstName}
                    {item.lastName}
                  </td>
                  <td>{item.email}</td>
                  <td>{item.mobileNumber}</td>
                  <td>{item.companyName}</td>
                  <td className="th">{item.physicalAddress}</td>
                  <td>
                    <label className="toggle-button">
                      <input
                        type="checkbox"
                        checked={item.status}
                        onChange={(e) =>
                          toggleUserStatus(item.id, e.target.checked)
                        }
                      />

                      <span className="slider round"></span>
                    </label>
                  </td>
                  <td className="actions th">
                    <div
                      className="view-container"
                      onClick={() => handleViewPage(item?.id)}
                    >
                      <img src={view} alt="img" />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <NoDataFound isShow={!isLoading} />
            )}
          </tbody>
        </table>
      </div>
      <RetailerPopup
        refreshRetailers={() =>
          getRetailers(
            setRetailersData,
            { limit, offset: 0 },
            setRetailerPaginationState
          )
        }
      />
      <Fragment>
        <Pagination
          totalRecords={retailerPaginationState?.total || 0}
          perPage={limit}
          handleFetchNextRecords={fetchNextRecords}
        />
      </Fragment>
    </Container>
  );
};

export default Retailer;
