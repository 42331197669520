import { Apis } from "libs/apis";
import { dispatch } from "store";
import { setModalClosed, stopLoading } from "store/user-slice";
import Swal from "sweetalert2";
import { RetailerDTO } from "utils/helpers/models/retailer.dto";

interface Filters {
  email?: string;
  mobileNumber?: string;
  companyName?: string;
  status?: string | boolean;
}

export default function useRetailers() {
  const postRetailer = async (body: any) => {
    await Apis.postRetailer(body)
      .then((res) => {
        if (res.data.status) {
          dispatch(setModalClosed("Ingredient"));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRetailers = async (
    setRetailersData: React.Dispatch<React.SetStateAction<any[]>>,
    filters: Filters & { limit?: number; offset?: number },
    setRetailerPaginationState: Function
  ) => {
    const queryParams = new URLSearchParams(
      filters as Record<string, string>
    ).toString();
    Apis.getRetailers(queryParams)
      .then((res) => {
        setRetailersData(res.data.data);
        setRetailerPaginationState(res.data.pagination);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => dispatch(stopLoading()));
  };

  const updateUserStatusById = async (userId: string, status: boolean) => {
    try {
      const res = await Apis.putUsers(userId, { status });
      if (res.data.status) {
        console.log("User status updated successfully:", res.data.updatedUser);
      }
    } catch (err) {
      console.error("Error updating user status:", err);
      throw err;
    }
  };

  return {
    postRetailer,
    getRetailers,
    updateUserStatusById,
  };
}
