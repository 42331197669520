export enum siteRoutes {
    login='/login',
    forgetPassword = '/forget-password',
    ResetPassword = '/reset-password',
    sentMessage = '/sent-message',
    dashboard = '/',
    categories = '/categories',
    ingredients = '/ingredient',
    ingredientDetail = '/ingredient/detail',
    users = '/user',
    usersView = '/user/view',
    usersViewPrint = '/user/view/print',
    retailerViewPrint = '/retailer/view/print',
    usersViewDetail = '/user/view/detail',
    homeChef = '/home-chef',
    homeChefView = '/home-chef/view',
    homeChefViewDetail = '/home-chef/view/detail',
    homeChefViewReceipe = '/home-chef/view-receipe',
    homeChefViewReceipeDetails = '/home-chef/view-receipe/details',
    retailer = '/retailer',
    retailerView = '/retailer/view',
    manageState = '/manage-state',
    manageStatePrint = '/manage-state/print',
    Banners = '/banners',
    profile = '/dashboard/profile',
    setting = '/dashboard/setting',
    notification = '/dashboard/notification'
}