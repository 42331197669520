import { limit } from "firebase/firestore";
import { Apis } from "libs/apis";

export const useHomeChefById = () => {
  const getHomeChefById = async (
    id: string,
    setData: Function,
    offset: number,
    limit: number
  ) => {
    await Apis.getDetailChiefById(id)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getDishesById = async (
    userId: string,
    dishName: string,
    userDietaryChoice: string,
    userState: string,
    setDishData: any,
    setDishesPaginationState: Function,
    limit?: number,
    offset?: number
  ) => {
    await Apis.getDishById(
      userId,
      dishName,
      userDietaryChoice,
      userState,
      limit,
      offset
    )
      .then((res) => {
        setDishData(res.data.data);
        setDishesPaginationState(res.data.pagination);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSessionById = async (
    homeCookId: string,
    status: string,
    setSessionData: any,
    createdAt: string,
    dishName: string,
    homeCookName: string,
    setSessionPaginationState: Function,
    limit: number,
    offset: number
  ) => {
    await Apis.getSessionDetailsForCook(
      homeCookId,
      status,
      createdAt,
      dishName,
      homeCookName,
      limit,
      offset
    )
      .then((res) => {
        setSessionData(res.data.data);
        setSessionPaginationState(res.data.pagination);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getReviewById = async (homeCookId: string, setReviewData: any) => {
    await Apis.getReviewById(homeCookId)
      .then((res) => {
        setReviewData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return { getHomeChefById, getDishesById, getSessionById, getReviewById };
};
