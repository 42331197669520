import { Apis } from "libs/apis";
export default function useUserDetails() {
  const getUserViewById = async (id: string, setData: any) => {
    await Apis.getDetailUserById(id)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOrderByUserById = async (
    userId: string,
    status: string | undefined,
    orderId: string | undefined,
    createdAt: string | undefined,
    retailerName: string | undefined,
    setOrderData: (data: any) => void,
    setOrdersPaginationState: Function,
    limit?: number,
    offset?: number
  ) => {
    await Apis.getOrderByUserById(
      userId,
      status,
      orderId,
      createdAt,
      retailerName,
      limit,
      offset
    ).then((res) => {
      setOrderData(res.data);
      setOrdersPaginationState(res.data.pagination);
    });
  };

  const getSessionByUserById = async (
    userId: string,
    status: string | undefined,
    setSessionData: (data: any) => void,
    sessionId: string | undefined,
    dishName: string | undefined,
    createdAt: string | undefined,
    homeCookName: string | undefined,
    setSessionsPaginationState: Function,
    limit?: number,
    offset?: number
  ) => {
    await Apis.getSessionByUserById(
      userId,
      status,
      sessionId,
      dishName,
      createdAt,
      homeCookName,
      limit,
      offset
    ).then((res) => {
      setSessionData(res.data.data);
      setSessionsPaginationState(res.data.pagination);
    });
  };
  return { getUserViewById, getOrderByUserById, getSessionByUserById };
}
