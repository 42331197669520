import React, { useState, useEffect } from "react";
import { Container } from "./style";

interface ReceipeOverviewProps {
  dishData: any;
}

const ReceipeOverview: React.FC<ReceipeOverviewProps> = ({ dishData }) => {
  const [overviewData, setOverview] = useState<any>({});

  useEffect(() => {
    if (dishData && Array.isArray(dishData) && dishData.length > 0) {
      setOverview(dishData[0]); // Assuming the first element contains the data
    }
  }, [dishData]);

  return (
    <Container>
      <div className="description">
        <h1>Description</h1>
        <p>{overviewData?.description || "No description available."}</p>
      </div>
      <div className="category-content">
        <h1>Dietary</h1>
        <div className="category-content-buttons">
          {overviewData?.dietaries?.length ? (
            overviewData.dietaries.map((category: any, index: number) => (
              <div key={index} className="dietary-item">
                {/* <img
                  src={category.dietaryImage}
                  alt={category.dietaryName}
                  width={30}
                  height={30}
                /> */}
                <p>{category.dietaryName}</p>
              </div>
            ))
          ) : (
            <p>No dietary information available.</p>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ReceipeOverview;
