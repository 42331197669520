import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import notificationIcon from "assets/images/notification-icon.svg";
import dropdownButton from "assets/images/dropdown.svg";
import {
  ProfileDropdownContainer,
  NavSection,
  Container,
  NotificationBadge,
  NotificationButton,
  ProfileContainer,
  DropdownItem,
  Divider,
  Icon,
} from "./styles";

import useComponentVisible from "hooks/click-outside-hook";
import { siteRoutes } from "utils/helpers/enums/routes.enums";
import { RootState, dispatch } from "store";
import { setSideBarClosed, setSideBarOpened } from "store/user-slice";
import { useSelector } from "react-redux";
import { ProfileDTO } from "utils/helpers/models/profile.dto";
import dummyProfile from "assets/images/dummy-profile-pic.png";
import { manageAdmin } from "./useHook";
import {
  getNotifications,
  markNotificationsAsRead,
} from "../../../firebase/notification";
import { onMessageListener } from "../../../firebase/firebase.config";

interface Notification {
  id: string;
  isRead: boolean;
  message?: string;
  timestamp?: number;
}

const Navbar: React.FC = () => {
  const { getUserByToken } = manageAdmin();
  const { isSidebarExpanded } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const [adminProfile, setAdminProfile] = useState<ProfileDTO>();
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const {
    isComponentVisible: isNotificationVisible,
    ref: notificationRef,
    setIsComponentVisible: setIsNotificationComponentVisible,
  } = useComponentVisible(false);

  const {
    isComponentVisible: profileDropDownOpened,
    ref: profileDropdownRef,
    setIsComponentVisible: setProfileDropdownOpened,
  } = useComponentVisible(false);

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleExpandSidebar = () => {
    if (isSidebarExpanded) {
      dispatch(setSideBarClosed());
    } else {
      dispatch(setSideBarOpened());
    }
  };

  const storedProfile: any = localStorage.getItem("adminProfile");

  useEffect(() => {
    getUserByToken(setAdminProfile);
  }, [storedProfile]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const notifications: Notification[] = await getNotifications();
      setNotifications(notifications);
      const unreadNotifications = notifications.filter(
        (notif) => !notif.isRead
      );
      setNotificationCount(unreadNotifications.length);
    };

    fetchNotifications();

    const handleNewNotification = (newNotification: Notification) => {
      setNotifications((prevNotifications) => [
        newNotification,
        ...prevNotifications,
      ]);
      setNotificationCount((prevCount) => prevCount + 1);
    };

    onMessageListener().then((payload) => {
      // console.log("Real-time message received: ", payload);
      const newNotification = {
        id: payload.notificationId,
        isRead: false,
        message: payload.notificationMessage,
        timestamp: Date.now(),
      };
      handleNewNotification(newNotification);
    });

    fetchNotifications();
  }, []);

  const refreshProfile = () => {
    getUserByToken(setAdminProfile);
  };

  const handleNotificationClick = async () => {
    await markNotificationsAsRead();
    setNotificationCount(0);
  };

  return (
    <Container className="no-print">
      <div className="navbar">
        <div className="title">
          <div>
            <Icon size={30} color="#000000" onClick={handleExpandSidebar} />
          </div>
          <h1>Hello Admin 👋🏼,</h1>
        </div>
        <NavSection>
          <div
            onClick={() => {
              navigate(siteRoutes.notification);
              handleNotificationClick(); 
            }}
          >
            <NotificationButton>
              {notificationCount > 0 && (
                <NotificationBadge>{notificationCount}</NotificationBadge>
              )}
              <img src={notificationIcon} alt="Notification Icon" />
            </NotificationButton>
          </div>
          <ProfileContainer
            onClick={() => setProfileDropdownOpened(!profileDropDownOpened)}
          >
            <img
              className="profile-image"
              src={adminProfile?.image ? adminProfile?.image : dummyProfile}
              alt="avatar"
            />
            <div>
              <h5>{adminProfile?.name}</h5>
              <p>{adminProfile?.userRole}</p>
            </div>
            <img src={dropdownButton} alt="Dropdown Button" />
          </ProfileContainer>
          <div>
            {profileDropDownOpened && (
              <ProfileDropdownContainer ref={profileDropdownRef}>
                <DropdownItem
                  onClick={() => {
                    setProfileDropdownOpened(false);
                    navigate(siteRoutes.profile);
                  }}
                >
                  Profile
                </DropdownItem>
                <Divider />
                <DropdownItem
                  onClick={() => {
                    setProfileDropdownOpened(false);
                    navigate(siteRoutes.setting);
                  }}
                >
                  Settings
                </DropdownItem>
                <Divider />
                <DropdownItem onClick={logout}>Logout</DropdownItem>
              </ProfileDropdownContainer>
            )}
          </div>
        </NavSection>
      </div>
    </Container>
  );
};

export default Navbar;
