export class DietariesDTO {
    name:string = ''
    image:any
    status:any
    id !:number 
    dietaryStatus !: boolean
}

export  class dietarySearchDTO{
        diateryName :string =''
        dietariesStatus : string = '--'
}