import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_Fire_BASE_ApiKey,
  authDomain: process.env.REACT_APP_Fire_BASE_AuthDomain,
  databaseURL: process.env.REACT_APP_Fire_BASE_DatabaseURL,
  projectId: process.env.REACT_APP_Fire_BASE_ProjectId,
  storageBucket: process.env.REACT_APP_Fire_BASE_StorageBucket,
  messagingSenderId: process.env.REACT_APP_Fire_BASE_MessagingSenderId,
  appId: process.env.REACT_APP_Fire_BASE_AppId,
  measurementId: process.env.REACT_APP_Fire_BASE_MeasurementId,
};

// Initialize Firebase app
export const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
export const messaging = getMessaging(app);

// Initialize Firestore
export const db = getFirestore(app);

export const onMessageListener = (): Promise<any> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Message received: ", payload);
      resolve(payload);
    });
  });
