import axios from "axios";
import { dispatch } from "store";
import { startLoading, stopLoading } from "store/user-slice";
import Swal from "sweetalert2";

export const Request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const useHttp = () => {
  function configureHeaders() {
    Request.interceptors.request.use(
      (config) => {
        dispatch(startLoading());
        const accessToken = localStorage.getItem("token");
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  const configureInterceptors = () => {
    Request.interceptors.response.use(
      (response) => {
        dispatch(stopLoading());
        return response;
      },
      async (error) => {
        const backendMessage =
          error.response?.data?.message || "An unknown error occurred";
        Swal.fire({
          // icon: "error",
          // title: "Oops...",
          text: backendMessage,
        });
        dispatch(stopLoading());

        console.error("API Error:", error.response?.data || error);
        return Promise.reject(error);
      }
    );
  };

  return { configureHeaders, configureInterceptors };
};

export default useHttp;
