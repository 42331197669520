import styled from "styled-components";

export const Container = styled.div`
width: 100%;
padding: 2rem;
display: flex;
flex-direction: column;
gap: 2rem;

h1{
    font-weight: 600;
}

td , th{
    width: 18rem !important;
}

.th{
    width: 10rem !important;
}

`