import user from "assets/images/user-dashboard.svg";
import chefs from "assets/images/dashboard-chefs.svg";
import retailer from "assets/images/dashboard-retailer.svg";
import arrowUp from "assets/images/arrow up.svg";
import arrowDown from "assets/images/arrow-down.svg";
import { FC, useEffect, useState } from "react";
import { Container, TopTiles, InfoTile, TableContainer } from "./style";
import { DashboardTopTilesData, tableData } from "utils/helpers/dummyData";
import {
  DashboardOrderOverviewChart,
  DashboardSessionOverviewChart,
} from "components/Graph/index";
import AreaChart from "components/Graph/area-chart";
import rating from "assets/images/RatingStar.svg";
import NoDataFound from "components/particles/no-data-found";
import { useSelector } from "react-redux";
import { useDashboard } from "./useHook";

interface DashboardProps {}

interface GraphStats {
  data: any[];
}

const Dashboard: FC<DashboardProps> = () => {
  const { getUSerStats, getGraphStats } = useDashboard();
  const { isLoading } = useSelector((state: any) => state.user);
  const [userStats, setUserStats] = useState<any>([]);

  const [ordersGraphStats, setOrdersStats] = useState<GraphStats | any>(null);
  const [sessionsGraphStats, setSessionsStats] = useState<GraphStats | any>(
    null
  );
  const [salesGraphStats, setSalesGraphStats] = useState<GraphStats | null>(
    null
  );

  const [ordersPeriod, setOrdersPeriod] = useState<string>("thisWeek");
  const [sessionsPeriod, setSessionsPeriod] = useState<string>("thisWeek");
  const [salesPeriod, setSalesPeriod] = useState<string>("thisWeek");
  const [overviewText, setOverviewText] = useState<string>(
    "Overview of this week"
  );

  useEffect(() => {
    getUSerStats(setUserStats);
  }, []);

  useEffect(() => {
    getGraphStats(
      ordersPeriod,
      setOrdersStats,
      setSessionsStats,
      setSalesGraphStats
    );
  }, [ordersPeriod]);

  useEffect(() => {
    getGraphStats(
      ordersPeriod,
      setOrdersStats,
      setSessionsStats,
      setSalesGraphStats
    );
  }, [salesPeriod]);

  useEffect(() => {
    getGraphStats(
      ordersPeriod,
      setOrdersStats,
      setSessionsStats,
      setSalesGraphStats
    );
  }, [sessionsPeriod]);

  const orderRatio = (ordersGraphStats?.ratio * ordersGraphStats?.total) / 100;
  const orderFinalRatio: number = isNaN(orderRatio)
    ? 0
    : parseFloat(orderRatio.toFixed(2));

  const sessionRatio =
    (sessionsGraphStats?.ratio * sessionsGraphStats?.total) / 100;
  const sessionFinalRatio: number = isNaN(sessionRatio)
    ? 0
    : parseFloat(sessionRatio.toFixed(2));

  const getPerformanceTextWithFallback = (ratio: number): string => {
    if (ratio === 0) {
      return "--";
    }
    return getPerformanceText(ratio);
  };

  const handleSalesPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPeriod = e.target.value;
    setSalesPeriod(selectedPeriod);

    switch (selectedPeriod) {
      case "thisWeek":
        setOverviewText("Overview of this week");
        break;
      case "twoWeeks":
        setOverviewText("Overview of two weeks");
        break;
      case "thisMonth":
        setOverviewText("Overview of this month");
        break;
      case "thisYear":
        setOverviewText("Overview of this year");
        break;
      default:
        setOverviewText("Overview of this week");
    }
  };

  if (isLoading || !userStats) {
    return <div>Loading...</div>;
  }

  const DashboardTopTilesData = [
    {
      icon: chefs,
      name: "Total Customers",
      value: userStats.totalCustomers?.count || 0,
      percentage: `${Math.round(userStats.totalCustomers?.percentage || 0)}%`,
      image: arrowUp,
      color: "#292D32",
    },
    {
      icon: chefs,
      name: "Total Home Cooks",
      value: userStats.totalChefs?.count || 0,
      percentage: `${Math.round(userStats.totalChefs?.percentage || 0)}%`,
      image: arrowDown,
      color: "#FF2121",
    },
    {
      icon: retailer,
      name: "Total Retailers",
      value: userStats.totalRetailers?.count || 0,
      percentage: `${Math.round(userStats.totalRetailers?.percentage || 0)}%`,
      image: arrowDown,
      color: "#FF2121",
    },
    {
      icon: user,
      name: "Total Users",
      value: userStats.totalUsers?.count || 0,
      percentage: `${Math.round(userStats.totalUsers?.percentage || 0)}%`,
      image: arrowUp,
      color: "#292D32",
    },
  ];

  const getPerformanceText = (ratio: number): string => {
    if (ratio > 80) {
      return "Excellent";
    } else if (ratio > 70) {
      return "Satisfactory";
    } else if (ratio > 60) {
      return "Good";
    } else {
      return "Average";
    }
  };

  return (
    <Container>
      <div className="title">
        <h1>Dashboard</h1>
      </div>
      <TopTiles>
        {DashboardTopTilesData.map((item, index) => (
          <InfoTile
            key={index}
            last={index === DashboardTopTilesData.length - 1}
          >
            <img src={item.icon} alt={item.name} />
            <div>
              <h5>{item.name}</h5>
              <h1>{item.value.toLocaleString()}</h1>
              {/* <div className="percentage-graph">
                <img src={item.image} alt="img" />
                <span style={{ color: item.color }}>
                  {item.percentage}
                </span>{" "}
                this month
              </div> */}
            </div>
          </InfoTile>
        ))}
      </TopTiles>
      <div className="graph-container">
        <div className="graph-left">
          <div className="title">
            <h1>Overview</h1>
            <div className="dropdown-container">
              <select
                name="sales"
                id="sales"
                value={salesPeriod}
                onChange={handleSalesPeriodChange}
              >
                <option value="thisWeek">This week</option>
                <option value="twoWeeks">Two weeks</option>
                <option value="thisMonth">This month</option>
                <option value="thisYear">This Year</option>
              </select>
            </div>
          </div>
          <AreaChart
            overviewText={overviewText}
            graphStats={{ salesGraphStats }}
          />
        </div>
        <div className="graph-right">
          <div className="graph-cart">
            <div className="title">
              <h1>Orders Overview </h1>
              <div className="dropdown-container">
                <select
                  name="orders"
                  id="orders"
                  value={ordersPeriod}
                  onChange={(e) => setOrdersPeriod(e.target.value)}
                >
                  <option value="thisWeek">This week</option>
                  <option value="twoWeeks">Two weeks</option>
                  <option value="thisMonth">This month</option>
                  <option value="thisYear">This Year</option>
                </select>
              </div>
            </div>
            <div className="graph">
              <DashboardOrderOverviewChart
                text={getPerformanceTextWithFallback(orderFinalRatio)}
                percentage={orderFinalRatio}
                color={"#1B473B"}
              />
            </div>
          </div>
          <div className="graph-cart">
            <div className="title">
              <h1>Session Overview </h1>
              <div className="dropdown-container">
                <select
                  name="sessions"
                  id="sessions"
                  value={sessionsPeriod}
                  onChange={(e) => setSessionsPeriod(e.target.value)}
                >
                  <option value="thisWeek">This week</option>
                  <option value="twoWeeks">Two weeks</option>
                  <option value="thisMonth">This month</option>
                  <option value="thisYear">This Year</option>
                </select>
              </div>
            </div>
            <div className="graph">
              <DashboardSessionOverviewChart
                text={getPerformanceTextWithFallback(sessionFinalRatio)}
                percentage={sessionFinalRatio}
                color="#00B087"
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
