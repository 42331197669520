import { FC, Fragment, useEffect, useState } from "react";
import { Container } from "./style";
import Pagination from "components/particles/pagination/pagination";
import NoDataFound from "components/particles/no-data-found";
import view from "assets/images/view-icon.svg";

import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enums";
import Button from "components/particles/primary-button";
import { useHomeChef } from "./useHook";
import { useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { HomeChefListSearchDTO } from "utils/helpers/models/homeChef.dto";

const HomeChef: FC = () => {
  const { getHomeChef, updateUserStatusById } = useHomeChef();
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [homeCookPaginationState, setHomeCookPaginationState] = useState<any>(
    {}
  );
  const {
    register,
    handleSubmit,
    setValue,
    reset: formReset,
  } = useForm<HomeChefListSearchDTO>();
  const isLoading = useSelector((state: any) => state.user.isLoading);
  const search = (filters: any) => {
    let statusValue;
    if (filters.status === "Active") {
      statusValue = true;
    } else if (filters.status === "Inactive") {
      statusValue = false;
    } else {
      statusValue = "";
    }

    getHomeChef(
      setData,
      { ...filters, status: statusValue, limit, offset: 0 },
      setHomeCookPaginationState
    );
  };

  const reset = () => {
    setValue("name", "");
    setValue("mobileNumber", "");
    setValue("gender", "");
    setValue("status", "");
    formReset();

    getHomeChef(setData, {}, setHomeCookPaginationState);
  };

  const limit = 10;
  const fetchNextRecords = (offset: number) => {
    getHomeChef(setData, { limit, offset }, setHomeCookPaginationState);
  };

  useEffect(() => {
    getHomeChef(setData, {}, setHomeCookPaginationState);
  }, []);

  const handleViewPage = (id: string) => {
    navigate(siteRoutes.homeChefView, { state: { id } });
  };

  const toggleUserStatus = async (userId: string, status: boolean) => {
    const updatedData = data.map((chef) =>
      chef.id === userId ? { ...chef, status: !status } : chef
    );
    setData(updatedData);
    await updateUserStatusById(userId, updatedData, !status);
  };
  const columns: any = [
    {
      name: "Name",
      class: "",
    },
    {
      name: "Email",
      class: "",
    },
    {
      name: "Phone Number",
      class: "",
    },
    {
      name: "Gender",
      class: "th",
    },
    {
      name: "Location",
      class: "th",
    },
    {
      name: "Status",
      class: "th",
    },
    {
      name: "Actions",
      class: "th",
    },
  ];

  const genderOptions = ["", "Male", "Female"];
  const statusOptions = ["", "True", "False"];

  return (
    <Container>
      <h1>Home cook List</h1>
      <div className="search-container">
        <div className="input-fields">
          <input
            type="text"
            placeholder="Search By Name"
            {...register("name")}
          />
          <input
            type="text"
            placeholder="Search by Phone Number"
            {...register("mobileNumber")}
          />
          <select id="gender" {...register("gender")}>
            <option value="">Gender</option>
            {genderOptions.slice(1).map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
          <select id="status" {...register("status")}>
            <option value="">Status</option>
            {statusOptions.slice(1).map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="button-fields">
          <Button
            title="Search"
            width="16rem"
            fill="true"
            onclick={handleSubmit(search)}
          />
          <Button
            title="Reset"
            width="16rem"
            fill="true"
            backgroundcolor="var(--reset-button)"
            onclick={reset}
          />
        </div>
      </div>

      <div className="data-table">
        <table>
          <thead>
            <tr>
              {columns.map((column: any, index: number) => {
                return (
                  <th className={column.class} key={index}>
                    {column.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((chef, index) => (
                <tr key={index}>
                  <td>
                    <img
                      src={
                        chef?.image ? chef?.image : "https://picsum.photos/200"
                      }
                      alt="img"
                      height={40}
                      width={40}
                      style={{ borderRadius: "50%" }}
                    />{" "}
                    {chef.name}
                  </td>
                  <td>{chef?.email}</td>
                  <td>{chef?.mobileNumber}</td>
                  <td className="th">{chef?.gender}</td>
                  <td className="th">
                    {chef?.location ? chef?.location : "Pakistan"}
                  </td>
                  <td>
                    <label className="toggle-button">
                      <input
                        type="checkbox"
                        checked={chef.status}
                        onChange={() => toggleUserStatus(chef.id, chef.status)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </td>

                  <td className="actions th">
                    <div
                      className="view-container"
                      onClick={() => handleViewPage(chef?.id)}
                    >
                      <img src={view} alt="img" />
                    </div>
                    {/* <img src={deleteIcon} alt='img' onClick={handleDelete}/> */}
                  </td>
                </tr>
              ))
            ) : (
              <NoDataFound isShow={!isLoading} />
            )}
          </tbody>
        </table>
      </div>
      <Fragment>
        <Pagination
          totalRecords={homeCookPaginationState?.total || 0}
          perPage={limit}
          handleFetchNextRecords={fetchNextRecords}
        />
      </Fragment>
    </Container>
  );
};

export default HomeChef;
