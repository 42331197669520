import { createGlobalStyle } from "styled-components";

export const VariableStyles = createGlobalStyle`
    :root{
    --primary: #1B473B;
    --secondary:#0C3A2D;
    --reset-button:#00B087;
    --text:#808D9E;
    --light-gray: #F9F9F9;
    --shadow-black:#FFFFFF0D;
    --medium-black:#121212;
    --white:#FFFFFF;
    --light-brown:#B36618
}
`;