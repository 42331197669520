import { Apis } from "libs/apis";

const useNotifications = () => {
  const getNotifications = async (
    setNotifications: Function,
    offset: number,
    limit: number
  ) => {
    try {
      const response = await Apis.getNotifications(offset, limit);
      setNotifications(response.data.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  return { getNotifications };
};

export { useNotifications };
