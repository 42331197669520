import { Apis } from "libs/apis";
interface Filters {
  name?: string;
  mobileNumber?: string;
  gender?: string;
  status?: string;
}
export const useHomeChef = () => {
  const getHomeChef = (
    setData: React.Dispatch<React.SetStateAction<any[]>>,
    filters: Filters & { limit?: number; offset?: number },
    setHomeCookPaginationState: Function
  ) => {
    const queryParams = new URLSearchParams(
      filters as Record<string, string>
    ).toString();
    Apis.getChef(queryParams)
      .then((res) => {
        setData(res.data.data);
        setHomeCookPaginationState(res.data.pagination);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateUserStatusById = async (
    userId: string,
    updatedUserData: any[],
    status: boolean
  ) => {
    try {
      const res = await Apis.putUsers(userId, { status });

      if (res.data.status) {
      }
    } catch (err) {
      console.error("Error updating user status:", err);
    }
  };

  return { getHomeChef, updateUserStatusById };
};
