import { ChangeEvent, FC, Fragment, useEffect, useState } from "react";
import { Container, TabeContainer, TabeCart, Tbody } from "./style";
import Pagination from "components/particles/pagination/pagination";
import categoriesDarkIcon from "assets/images/categories-tab-dark-icon.svg";
import categoriesIcon from "assets/images/categories-tab-icon.svg";

import dietaryIcon from "assets/images/dietary-white-icon.svg";
import dietaryDarkIcon from "assets/images/diteryIcon.svg";
import { useSelector } from "react-redux";
import { setCategoriesActiveButton, setModalOpened } from "store/user-slice";
import AddCategoryModal from "components/popus/categories-popup/add-category";

import SelectIngredientModel from "components/popus/categories-popup/select-ingredients";
import link from "assets/images/link.svg";
import NoDataFound from "components/particles/no-data-found";
import edit from "assets/images/edit.svg";
import Button from "components/particles/primary-button";

import useCategories from "./useHook";
import { set, useForm } from "react-hook-form";
import {
  categoriesSearchDTO,
  CategoryDTO,
} from "utils/helpers/models/categories.dto";
import view from "assets/images/view-icon.svg";
import LinkedIngredientModal from "components/popus/categories-popup/linked-ingredient";

import { dispatch } from "store";
import AddDietariesModal from "components/popus/dietaries-popup/add-dietaries";
import DietariesSelectIngredientModel from "components/popus/dietaries-popup/select-ingredients";
import DietariesLinkedIngredientModal from "components/popus/dietaries-popup/linked-ingredient";
import {
  DietariesDTO,
  dietarySearchDTO,
} from "utils/helpers/models/dietaries.dto";
import { reset } from "utils/helpers/models/users.dto";

const Categories: FC = () => {
  const [categoryPaginationState, setCategoryPaginationState] = useState<any>(
    {}
  );
  console.log(categoryPaginationState, "categoryPaginationState");
  const [dietariesPaginationState, setDietariesPaginationState] = useState<any>(
    {}
  );
  const [categoryId, setCategoryId] = useState<number>(0);
  const [categoriesData, setCategoriesData] = useState<any[]>([]);
  const [categoriesDataById, setCategoriesDataById] = useState<any>();
  const [linkedIngredientData, setLinkedIngredientData] = useState<any[]>([]);
  const [selectIngredientData, setSelectedIngredientData] = useState<any[]>([]);
  const [status, setStatus] = useState<string>();
  const [dietaryId, setDietaryId] = useState<number>(0);
  const [dietariesData, setDietariesData] = useState<any[]>([]);
  const [dietariesstatusData, setDietariesstatusData] = useState<any[]>([]);

  const [dietariesDataById, setDietariesDataById] = useState<any>();

  const [dietariesSelectIngredientData, setDietariesSelectedIngredientData] =
    useState<any[]>([]);
  const [dietaryLinkedData, setDietaryLinkedData] = useState<any[]>([]);
  const [dietariesStatus, setDietariesStatus] = useState<string>();

  const {
    categoriesActiveButton,
    addCategoriesModalIsOpen,
    addDietariesModalIsOpen,
    selectIngredientModalIsOpen,
    linkedIngredientModalIsOpen,
    dietariesSelectIngredientModalIsOpen,
    dietariesLinkedIngredientModalIsOpen,
    isLoading,
  } = useSelector((state: any) => state.user);

  const {
    getCategories,
    getDietaries,
    getUnLinkedCategoriesIngredients,
    getDietariesLinkedIngredientById,
    putCategoryById,
    putDietaries,
    getunLinkedDietariesIngredients,
    searchCategoriesIngredients,
    getCategoryLinkedIngredientById,
    searchDietaries,
    updateCategoryByStatus,
    updateCategoryDietary,
  } = useCategories();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset: formReset,
  } = useForm<any>();

  useEffect(() => {
    getCategories(setCategoriesData, setCategoryPaginationState);
  }, [
    addCategoriesModalIsOpen,
    selectIngredientModalIsOpen,
    linkedIngredientModalIsOpen,
  ]);

  useEffect(() => {
    getDietaries(setDietariesData, setDietariesPaginationState);
  }, [
    addDietariesModalIsOpen,
    dietariesSelectIngredientModalIsOpen,
    dietariesLinkedIngredientModalIsOpen,
  ]);

  const searchCategories = (type: string, data: categoriesSearchDTO) => {
    if (type === "search") {
      searchCategoriesIngredients(data, setCategoriesData);
    } else {
      getCategories(setCategoriesData, setCategoryPaginationState);
    }
  };

  const dietariesSearch = (type: string, data: dietarySearchDTO) => {
    if (type === "search") {
      searchDietaries(data, setDietariesData);
    } else {
      getDietaries(setDietariesData, setDietariesPaginationState);
    }
  };

  const resetsearchCategories = () => {
    setValue("categoryName", "");
    setValue("status", "");
    setStatus("--");
    formReset();
    getCategories(setCategoriesData, setCategoryPaginationState);
  };

  const resetdietariesSearch = () => {
    setValue("diateryName", "");
    setValue("dietariesStatus", "");
    setDietariesStatus("");
    formReset();

    getDietaries(setDietariesData, setDietariesPaginationState);
  };

  const addCategories = (id: number, data?: CategoryDTO) => {
    setCategoryId(id);
    dispatch(setModalOpened("Categories"));
    if (data) {
      setCategoriesDataById(data);
    }
  };

  const openIngredientPopupForLink = (id: number) => {
    setCategoryId(id);
    dispatch(setModalOpened("Select Ingredient"));
    getUnLinkedCategoriesIngredients(setSelectedIngredientData);
  };

  const openIngredientPopupForUnLinked = (id: number) => {
    setCategoryId(id);
    dispatch(setModalOpened("Linked Ingredient"));

    setLinkedIngredientData([]);
    getCategoryLinkedIngredientById(id, (data: any[]) => {
      setLinkedIngredientData(data || []);
    });
  };

  const addDietaries = (id: number, data?: DietariesDTO) => {
    setDietaryId(id);
    dispatch(setModalOpened("Dietaries"));
    if (data) {
      console.log(data, "data");
      setDietariesDataById(data);
    }
  };

  const openDietariesIngredientPopupForLink = (id: number) => {
    setDietaryId(id);
    dispatch(setModalOpened("Dietaries Select Ingredient"));
    getunLinkedDietariesIngredients(id, setDietariesSelectedIngredientData);
  };

  const openDietariesIngredientPopupForUnLink = (id: number) => {
    setDietaryId(id);
    dispatch(setModalOpened("Dietaries Linked Ingredient"));
    setDietaryLinkedData([]);
    getDietariesLinkedIngredientById(id, (data: any[]) => {
      setDietaryLinkedData(data || []);
    });
  };

  const limit = 10;
  function fetchNextRecords(offset: number) {
    if (categoriesActiveButton === "Categories") {
      getCategories(
        setCategoriesData,
        setCategoryPaginationState,
        offset,
        limit
      );
    } else {
      getDietaries(
        setDietariesData,
        setDietariesPaginationState,
        offset,
        limit
      );
    }
    console.log(offset);
  }

  const toggleStatus = (category: CategoryDTO, index: number) => {
    categoriesData[index] = { ...category };
    setCategoriesData([...categoriesData]);
    updateCategoryByStatus(category?.id, category);
  };

  const toggleDietariesStatus = (dietory: DietariesDTO, index: number) => {
    dietariesData[index] = { ...dietory };
    setDietariesData([...dietariesData]);
    updateCategoryDietary(dietory?.id, dietory);
  };

  const columns: string[] = [
    "Image",
    "Name",
    "No. of ingredients",
    "Status",
    "Actions",
  ];

  const handleStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newStatus = e.target.value;
    setStatus(newStatus);
    setValue("status", newStatus);
  };
  const handleDietariesStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newStatus = e.target.value;
    setDietariesStatus(newStatus);
    setValue("dietariesStatus", newStatus);
  };

  // I m commiting something just for testing purpose
  return (
    <>
      <Container>
        <div className="categories-title">
          <h1>Categories Type</h1>
          <Button
            title={
              categoriesActiveButton === "Categories"
                ? "Add Categories"
                : "Add Dietaries"
            }
            width="16rem"
            fill="true"
            backgroundcolor="var(--reset-button)"
            onclick={
              categoriesActiveButton === "Categories"
                ? () => addCategories(0)
                : () => addDietaries(0)
            }
          />
        </div>

        <TabeContainer>
          <TabeCart
            active={categoriesActiveButton === "Categories"}
            onClick={() => {
              dispatch(setCategoriesActiveButton("Categories"));
            }}
          >
            <div className="categories-tab-img">
              <img
                src={
                  categoriesActiveButton === "Categories"
                    ? categoriesIcon
                    : categoriesDarkIcon
                }
                alt="img"
              />
            </div>
            <h1>Categories</h1>
          </TabeCart>
          <TabeCart
            active={categoriesActiveButton === "Dietary"}
            onClick={() => {
              dispatch(setCategoriesActiveButton("Dietary"));
            }}
          >
            <div className="categories-tab-img">
              <img
                src={
                  categoriesActiveButton === "Dietary"
                    ? dietaryIcon
                    : dietaryDarkIcon
                }
                alt="img"
              />
            </div>
            <h1>Dietary</h1>
          </TabeCart>
        </TabeContainer>
        <div className="search-container">
          <div className="input-fields">
            {categoriesActiveButton === "Categories" ? (
              <>
                <input
                  type="text"
                  placeholder="Search By Name"
                  {...register("categoryName")}
                />
                <select
                  id="status"
                  {...register("status")}
                  value={status}
                  onChange={handleStatusChange}
                >
                  <option value="">Status</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </>
            ) : (
              <>
                <input
                  type="text"
                  placeholder="Search By Name"
                  {...register("diateryName")}
                />
                <select
                  id="status"
                  {...register("dietariesStatus")}
                  value={dietariesStatus}
                  onChange={handleDietariesStatusChange}
                >
                  <option value="">Status</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </>
            )}
          </div>
          <div className="button-fields">
            <Button
              title="Search"
              width="16rem"
              fill="true"
              onclick={
                categoriesActiveButton === "Categories"
                  ? handleSubmit((data) => searchCategories("search", data))
                  : handleSubmit((data) => dietariesSearch("search", data))
              }
            />
            <Button
              title="Reset"
              width="16rem"
              fill="true"
              backgroundcolor="var(--reset-button)"
              onclick={
                categoriesActiveButton === "Categories"
                  ? handleSubmit(() => resetsearchCategories())
                  : handleSubmit(() => resetdietariesSearch())
              }
            />
          </div>
        </div>
        <div className="data-table">
          <table>
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return (
                    <th scope="col" key={index}>
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <Tbody>
              {categoriesActiveButton === "Categories" ? (
                categoriesData?.length > 0 ? (
                  categoriesData?.map((item, index) => {
                    {
                    }
                    return (
                      <tr key={index}>
                        <td>
                          <img
                            src={
                              item?.image
                                ? item?.image
                                : "https://picsum.photos/300"
                            }
                            alt="img"
                            width={60}
                            height={60}
                            style={{ borderRadius: "50%" }}
                          />
                        </td>
                        <td>{item?.name}</td>
                        <td>{item?.ingredientsCount}</td>
                        <label className="toggle-button">
                          <input
                            type="checkbox"
                            checked={categoriesData?.[index]?.status}
                            onChange={(e) =>
                              toggleStatus(
                                {
                                  ...categoriesData[index],
                                  status: e.target.checked,
                                },
                                index
                              )
                            }
                          />
                          <span className="slider round"></span>
                        </label>

                        <td className="actions">
                          <div
                            className="view-container"
                            onClick={() =>
                              openIngredientPopupForUnLinked(item?.id)
                            }
                          >
                            <img src={view} alt="img" />
                          </div>
                          <button
                            onClick={() => openIngredientPopupForLink(item?.id)}
                            className="tooltip link-button"
                          >
                            <img src={link} alt="img" />
                            <span className="tooltiptext">Link Ingredient</span>
                          </button>
                          <div className="edit-container">
                            <img
                              src={edit}
                              alt="img"
                              onClick={() => addCategories(item.id, item)}
                              // onClick={() => addDietaries(item.id, item)}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoDataFound isShow={!isLoading} />
                )
              ) : dietariesData?.length > 0 ? (
                dietariesData?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className="flex gap-4 items-center">
                          <img
                            src={
                              item?.image
                                ? item?.image
                                : "https://picsum.photos/300"
                            }
                            alt="img"
                            width={60}
                            height={60}
                            style={{ borderRadius: "50%" }}
                          />
                        </div>
                      </td>
                      <td>{item.name}</td>
                      <td>{item.ingredientsCount}</td>
                      <td>
                        <label className="toggle-button">
                          <input
                            type="checkbox"
                            checked={dietariesData?.[index]?.status}
                            onChange={(e) =>
                              toggleDietariesStatus(
                                {
                                  ...dietariesData[index],
                                  status: e.target.checked,
                                },
                                index
                              )
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>

                      <td className="actions">
                        <div
                          className="view-container"
                          onClick={() =>
                            openDietariesIngredientPopupForUnLink(item?.id)
                          }
                        >
                          <img src={view} alt="img" />
                        </div>
                        <button
                          onClick={() =>
                            openDietariesIngredientPopupForLink(item?.id)
                          }
                          className="tooltip link-button"
                        >
                          <img src={link} alt="img" />
                          <span className="tooltiptext">Link Ingredient</span>
                        </button>
                        <div
                          className="edit-container"
                          onClick={() => addDietaries(item.id, item)}
                        >
                          <img src={edit} alt="img" />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoDataFound isShow={!isLoading} />
              )}
            </Tbody>
          </table>
        </div>
        <Fragment>
          <Pagination
            totalRecords={
              categoriesActiveButton === "Categories"
                ? categoryPaginationState?.total
                : dietariesPaginationState?.total
            }
            perPage={limit}
            handleFetchNextRecords={fetchNextRecords}
          />
        </Fragment>

        {addCategoriesModalIsOpen ? (
          <AddCategoryModal
            id={categoryId}
            data={categoriesDataById}
            categoriesData={categoriesData}
            setCategoriesData={setCategoriesData}
          />
        ) : (
          ""
        )}
        {selectIngredientModalIsOpen ? (
          <SelectIngredientModel id={categoryId} data={selectIngredientData} />
        ) : (
          ""
        )}
        {linkedIngredientModalIsOpen ? (
          <LinkedIngredientModal
            id={categoryId}
            data={linkedIngredientData}
            setData={setCategoriesData}
          />
        ) : (
          ""
        )}
        {addDietariesModalIsOpen ? (
          <AddDietariesModal
            id={dietaryId}
            data={dietariesDataById}
            dietariesData={dietariesData}
            setDietariesData={setDietariesData}
          />
        ) : (
          ""
        )}
        {dietariesSelectIngredientModalIsOpen ? (
          <DietariesSelectIngredientModel
            id={dietaryId}
            data={dietariesSelectIngredientData}
          />
        ) : (
          ""
        )}
        {dietariesLinkedIngredientModalIsOpen ? (
          <DietariesLinkedIngredientModal
            id={dietaryId}
            data={dietaryLinkedData}
            setData={setDietaryLinkedData}
          />
        ) : (
          ""
        )}
      </Container>
    </>
  );
};

export default Categories;
