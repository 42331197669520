import React, { useState, useEffect } from "react";
import { Container } from "./style";

interface ReceipeNutritionProps {
  dishData: any;
}

const Nutrition: React.FC<ReceipeNutritionProps> = ({ dishData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [nutritionData, setNutritions] = useState<any>([]);

  useEffect(() => {
    if (dishData && Array.isArray(dishData)) {
      setNutritions(dishData);
    }
  }, [dishData]);

  return (
    <Container>
      <h1>Nutrition Per Serving</h1>
      <div className="data-table">
        <table>
          <thead>
            <tr>
              <th>Nutrient</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {nutritionData.length > 0 ? (
              nutritionData[0].nutrition.map((item: any, index: number) => (
                <tr key={index}>
                  <td>{item?.nutrient}</td>
                  <td>{item?.value}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2}>No nutrition information available.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Container>
  );
};

export default Nutrition;
