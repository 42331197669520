import styled from "styled-components";

export const Container = styled.form`
  .modal-logo {
    background-color: #e6e6e6;
    border: 4px solid white;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 133px;
    height: 133px;
  }
  .logo-main {
    display: flex;
    justify-content: center;
    margin-top: 1.6rem;
    position: relative;
  }
  .model-container {
    margin-top: 50px;
    h1 {
      font-weight: 600;
    }
  }
  .model-header {
    background-color: #e6e6e6;
    height: 126px;
    width: 100%;
  }
  .upload-cam {
    position: absolute;
    left: 360px;
    top: 81px;
    border-radius: 200px;
  }
  .upload-main {
    display: flex;
    gap: 20px;
    justify-content: end;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 90px;
  }
  .upload-icon {
    width: 20px;
  }
  .model-title {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    border-radius: 1rem 1rem 0 0;
    position: relative;
    .close-button {
      z-index: 5;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
    }
    .tag-line-banner {
      z-index: 5;
      position: absolute;
      right: 40%;
      top: 1.5rem;
      color: #808d9e;
      font-weight: 600;
      font-size: 15px;
    }
  }

  .add-retailer-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-top: 1.5rem;
    width: 100%;
    padding: 0 2rem;

    .image-container {
      width: 100%;
    }

    .retailer-input {
      width: 48%;
    }
    input {
      height: 5.2rem;
      background-color: var(--light-gray);
      border-radius: 5px;
      outline-style: none;
      border: none;
    }
    .image-input {
      //   height: 200px;
    }
  }
`;

export const ImageContainer = styled.div`
  .modal-retailers {
    over-flow-y: auto;
    // overflow-y: hidden;
  }
`;
