import React from "react";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Graph } from "./style";
import sessionDarkIcon from "assets/images/session-dark-icon.svg";
import orderDarkIcon from "assets/images/order-dark-icon.svg";
Chart.register(ArcElement, Tooltip, Legend);

interface UsersOverviewProps {
  percentage: number;
  text: string;
  color: string;
}

const DashboardOrderOverviewChart: React.FC<UsersOverviewProps> = ({
  text,
  percentage,
  color,
}) => {
  return (
    <CircularProgressbarWithChildren
      styles={buildStyles({
        pathColor: color,
      })}
      value={percentage}
    >
      <Graph>
        <img
          style={{ width: 40, marginTop: -5, color:"black !important" }}
          src={orderDarkIcon}
          alt="doge"
        />
        <div className="graph">
          <span style={{ fontSize: "32px", fontWeight: 600 }}>
            {percentage}
          </span>
          <h1>{text}</h1>
        </div>
      </Graph>
    </CircularProgressbarWithChildren>
  );
};
const DashboardSessionOverviewChart: React.FC<UsersOverviewProps> = ({
  text,
  percentage,
  color,
}) => {
  return (
    <CircularProgressbarWithChildren
      styles={buildStyles({
        pathColor: color,
        // trailColor: "gold"
      })}
      value={percentage}
    >
      <Graph>
        <img
          style={{ width: 40, marginTop: -5 }}
          src={sessionDarkIcon}
          alt="doge"
        />
        <div className="graph">
          <span style={{ fontSize: "32px", fontWeight: 600 }}>
            {percentage}
          </span>
          <h1>{text}</h1>
        </div>
      </Graph>
    </CircularProgressbarWithChildren>
  );
};

export { DashboardOrderOverviewChart, DashboardSessionOverviewChart };
