import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend
);

const data = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Sessions",
      data: [100, 150, 200, 800, 950, 430, 160, 940, 220, 880, 910, 100],
      fill: true,
      borderColor: "rgba(255, 159, 64, 1)",
      backgroundColor: "rgba(255, 159, 64, 0.2)",
      borderDash: [5, 5],
      pointBackgroundColor: "rgba(255, 159, 64, 1)",
      tension: 0.4,
    },
    {
      label: "Orders",
      data: [100, 110, 220, 330, 440, 660, 740, 200, 810, 100, 750, 200],
      fill: true,
      borderColor: "rgba(54, 162, 235, 1)",
      backgroundColor: "rgba(54, 162, 235, 0.2)",
      borderDash: [5, 5],
      pointBackgroundColor: "rgba(54, 162, 235, 1)",
      tension: 0.4,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      // position: 'top' as const
      position: "top" as const,
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y;
          }
          return label;
        },
      },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: "Months",
      },
    },
    y: {
      title: {
        display: true,
        text: "Value",
      },
    },
  },
};

interface AreaChartProps {
  overviewText: string;
  graphStats?: {
    salesGraphStats: any;
  } | null;
}

const DualAreaChart: React.FC<AreaChartProps> = ({
  overviewText,
  graphStats,
}) => {
  const sessionGrapgStatsData = graphStats?.salesGraphStats?.sessions;
  const ordersGraphStatsData = graphStats?.salesGraphStats?.orders;

  return (
    <>
      <p>{overviewText}</p>
      <Line data={data} options={options} />
    </>
  );
};

export default DualAreaChart;
