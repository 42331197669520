import styled from "styled-components";

export const Graph = styled.div`
display: flex;
flex-direction: column;
gap: 1rem;
align-items: center;
justify-content: center;

.graph{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}
`