import { Apis } from "libs/apis";


export const usePrintRetailersById = () => {
  const getPrintViewByOrderId = async (orderId: string, setData: Function) => {
    await Apis.getPrintViewByOrderId(orderId)
      .then((res) => {
        setData(res.data.data);
        console.log(res.data.data,"print view retailor")
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return { getPrintViewByOrderId };
};
