import { Apis } from "libs/apis";

export const manageAdmin = () => {
  const updateAdmin = async (formData: FormData) => {
    try {
      const response = await Apis.updateAdmin(formData); 
      return response.data;
    } catch (error) {
      console.error("Error updating admin:", error);
      throw error;
    }
  };
  return { updateAdmin };
};
