import { Container } from "./style";
import NoDataFound from "components/particles/no-data-found";
import { useState, useEffect } from "react";

interface ReceipeIngredientsProps {
  dishData: any;
}

const Ingredients: React.FC<ReceipeIngredientsProps> = ({ dishData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ingredientsData, setIngredients] = useState<any>([]);

  useEffect(() => {
    if (dishData && Array.isArray(dishData)) {
      setIngredients(dishData);
    }
  }, [dishData]);

  return (
    <Container>
      <h1>Ingredients list</h1>

      <div className="data-table">
        <table>
          <tbody>
            {ingredientsData.length &&
              ingredientsData[0].ingredients.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>
                      <img
                        src={item.ingredientImage}
                        alt="ingredient"
                        width={60}
                        height={60}
                        style={{ borderRadius: "50%" }}
                      />
                      {item.ingredientName}
                    </td>
                    <td className="weight">
                      {item.dishViewingredientQuantity}{" "}
                      {item.dishViewingredientUnit}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Container>
  );
};

export default Ingredients;
