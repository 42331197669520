export class CategoryDTO{
    // id!:number;
    // image:any;
    // name:string = '';
    // status !: any
    // ingredientsCount :number = 0


    name:string = ''
    image:any
    status:any
    id !:number 
    categoryStatus !: boolean
}


export class LinkedCategoryIngredientsDTO{
    id !: number;
    image : any;
    name: string = ''
    status : any
}

export class categoriesSearchDTO {
    categoryName :string =''
    status : string = '--'
}