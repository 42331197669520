import { Apis } from "libs/apis";

export default function useGetDish() {
  const getDishByUserId = async (dishId: number, setDishData: any) => {
    try {
      const res = await Apis.getDishByIdForHomeCook(dishId);
      setDishData(res.data.data);
    } catch (error) {
      console.error("Error fetching dish details:", error);
    }
  };
  return { getDishByUserId };
}
