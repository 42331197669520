import { UserDTO } from "utils/helpers/models/user.dto";
import { Apis } from "libs/apis";
import { set } from "react-hook-form";

export const manageAdmin = () => {
  const getUserByToken = async (setUser: any) => {
    try {
      const response = await Apis.getUserByToken();
      setUser(response.data.data);
      console.log("User data fetched:", response.data.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setUser(null);
    }
  };
  const updateAdmin = async (body: UserDTO) => {
    try {
      const response = await Apis.updateAdmin(body);
      console.log("Admin updated:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating admin:", error);
      throw error;
    }
  };
  return { getUserByToken, updateAdmin };
};
