import React, { useRef, useState, useEffect } from "react";
import { Container, ImageWrapper, ImageContainer, Content } from "./style";
import Button from "components/particles/primary-button";
import { manageAdmin } from "./useHook";
import { UserDTO } from "utils/helpers/models/user.dto";
import ProfilePopup from "components/popus/profile-popup";

const { getUserByToken } = manageAdmin();

interface ProfileProps {
  setIsOpen: (isOpen: boolean) => void;
  userData?: UserDTO | any;
}

export const Profile: React.FC<ProfileProps> = ({ setIsOpen, userData }) => {
  const [user, setUser] = useState<UserDTO | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    getUserByToken(setUser);
  }, []);

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onProfileUpdated = () => {
    getUserByToken(setUser);
  };

  const handleUpdateProfile = () => {
    setIsPopupOpen(true);
  };

  return (
    <Container>
      <ImageWrapper>
        <ImageContainer onClick={handleImageClick}>
          <img src={user?.image || "httsps://picsum.photos/200"} alt="avatar" />
        </ImageContainer>
      </ImageWrapper>
      <Content>
        <div className="profile-cart">
          <h2>Name</h2>
          <p>{user?.name}</p>
        </div>
        <div className="profile-cart">
          <h2>Email</h2>
          <p>{user?.email}</p>
        </div>
        <div className="profile-cart">
          <h2>Phone</h2>
          <p>{user?.mobileNumber}</p>
        </div>
      </Content>
      <div>
        <Button
          title="Edit Profile"
          width="16rem"
          backgroundcolor="var(--medium-black)"
          fill="true"
          onclick={handleUpdateProfile}
        />
      </div>
      {isPopupOpen && (
        <ProfilePopup
          modalIsOpen={isPopupOpen}
          setIsOpen={setIsPopupOpen}
          userData={user}
          onProfileUpdated={onProfileUpdated}
        />
      )}
    </Container>
  );
};
