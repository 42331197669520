import { limit } from "firebase/firestore";
import { Request } from "hooks/useHttp";
import { useEffect } from "react";
import { BannerDTO } from "utils/helpers/models/banner.dto";
import {
  categoriesSearchDTO,
  CategoryDTO,
} from "utils/helpers/models/categories.dto";
import {
  CategoryLinkedIngredientDTO,
  DietaryLinkedIngredientDTO,
} from "utils/helpers/models/category-linked-ingredient.dto";
import { dietarySearchDTO } from "utils/helpers/models/dietaries.dto";
import { DietaryChoiceDTO } from "utils/helpers/models/dietaryChoice.dto";
import { IngredientDetailDTO } from "utils/helpers/models/ingredient-detail.dto";
import {
  IngredientDTO,
  IngredientSearchDTO,
} from "utils/helpers/models/ingredients.dto";
import { LoginDTO } from "utils/helpers/models/login.dto";
import { OnBoardingDTO } from "utils/helpers/models/onBoarding.dto";
import { SessionAmountDTO } from "utils/helpers/models/sessionAmount.dto";
import { TermConditionsDTO } from "utils/helpers/models/term&Conditions.dto";

export const Apis = {
  login: (body: LoginDTO) => Request.post("auth/login", body),
  forget: (body: any) => Request.post("auth/send-forgot-password-otp", body),
  reset: (body: any) => Request.post("auth/reset/password", body),
  verifyOtp: (body: any) => Request.post("auth/verify-otp", body),
  resendOtp: (body: any) => Request.post("auth/verifyResetOTP", body),
  saveFcm: (body: any) => Request.put("/fcm/token/update", body),

  updatePassword: (body: any) =>
    Request.put("auth/update-password", body, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }),

  postCategory: (body: any) => Request.post("categories", body),
  getCategories: (offset?: number, limit?: number) =>
    Request.get("category-ingredients/count", {
      params: {
        offset,
        limit,
      },
    }),
  getCategoryById: (id: number | null) => Request.get(`categories/${id}`),
  putCategoryById: (id: number, body: any) =>
    Request.put(`categories/${id}`, body),
  updateStatusCategories: (id: number, body: CategoryDTO) =>
    Request.put(`categories/${id}`, body),
  updateStatusDietaries: (id: number, body: CategoryDTO) =>
    Request.put(`diateries/${id}`, body),
  postCategoryLinkedIngredient: (body: CategoryLinkedIngredientDTO) =>
    Request.post(`category-ingredients/add-ingredients`, body),
  getCategoryLinkedIngredientById: (categoryId: number) =>
    Request.get(`category-ingredients/${categoryId}`),
  getUnLinkedCategoriesIngredients: () =>
    Request.get(`category-ingredients/available`),
  unLinkedCategoryIngredients: (id: number) =>
    Request.delete(`category-ingredients/${id}`),
  searchCategoriesIngredients: (data: categoriesSearchDTO) =>
    Request.get(
      `category-ingredients/search?${
        data.categoryName ? `categoryName=${data.categoryName}` : ""
      }${
        data.status
          ? `${data.categoryName ? "&" : ""}status=${data.status}`
          : ""
      }`
    ),
  getDietaries: (offset?: number, limit?: number) =>
    Request.get(`/diatery-ingredients/count`, { params: { offset, limit } }),
  // getDietaries: () => Request.get(`diatery-ingredients/search`),
  getDietariesById: (id: number) => Request.get(`/diateries/${id}`),
  postDietaries: (body: any) => Request.post(`diateries`, body),
  putDietaries: (id: number, body: any) => Request.put(`diateries/${id}`, body),
  getDietariesLinkedIngredientById: (dietaryId: number) =>
    Request.get(`diatery-ingredients/${dietaryId}`),
  getunLinkedDietaries: (dietaryId: number) =>
    Request.get(`diatery-ingredients/unlinked/${dietaryId}`),
  unLinkedDietaryIngredients: (id: number) =>
    Request.delete(`diatery-ingredients/${id}`),
  postDietaryLinkedIngredients: (
    dietaryId: number,
    body: DietaryLinkedIngredientDTO
  ) => Request.put(`/diatery-ingredients/${dietaryId}`, body),
  searchDietaries: (data: dietarySearchDTO) =>
    Request.get(
      `diatery-ingredients/search?${
        data?.diateryName ? `diateryName=${data?.diateryName}` : ""
      }&${data?.dietariesStatus ? `status=${data?.dietariesStatus}` : ""}`
    ),
  postIngredient: (body: IngredientDTO) => Request.post("ingredients", body),
  putIngredient: (id: number, body: IngredientDTO) =>
    Request.put(`/ingredients/${id}`, body),
  getIngredients: () => Request.get(`ingredients`),
  getIngredientsAllAndFiltered: (queryParams: boolean | string) => {
    console.log("queryParams", queryParams);
    if (typeof queryParams === "string" && queryParams) {
      return Request.get(`ingredients/idAndName?${queryParams}`);
    }
    return Request.get(`ingredients/idAndName`);
  },
  getIngredientById: (id: number | null) => Request.get(`ingredients/${id}`),
  deleteIngredient: (id: number) => Request.delete(`/ingredients/${id}`),
  getIngredientDetail: () => Request.get("ingredients-details"),
  postIngredientDetail: (body: IngredientDetailDTO) =>
    Request.post(`ingredients-details`, body),
  getIngredientDetailById: (id: number) =>
    Request.get(`ingredients-details/search?ingredientId=${id}`),
  putIngredientDetail: (id: number, body: IngredientDetailDTO) =>
    Request.put(`ingredients-details/${id}`, body),
  deleteIngredientDetail: (id: number) =>
    Request.delete(`ingredients-details/${id}`),
  searchIngredients: (data: IngredientSearchDTO) =>
    Request.get(
      `diatery-ingredients/search?${
        data?.name ? `ingredientName=${data?.name}` : ""
      }&${data?.status ? `status=${data?.status}` : ""}`
    ),
  uploadIngredientImage: (body: any) =>
    Request.post(`ingredient/upload-image`, body),
  getUsers: (offset: number, limit: number) =>
    Request.get(`users?userRole=user&offset=${offset}&limit=${limit}`),
  deleteUser: (id: string) => Request.delete("users"),
  getChef: (queryParams: string) =>
    Request.get(`users?userRole=homeCook&${queryParams}`),
  getChefById: (id: string, offset: Number, limit: Number) =>
    Request.get(`chef/${id}?page=${offset}&limit=${limit}`),
  postBanner: (body: FormData) => Request.post(`banners`, body),
  getBanner: () => Request.get(`banners`),
  getBannerById: (id: number) => Request.get(`banners/${id}`),
  putBanner: (id: number, body: FormData) =>
    Request.put(`/banners/${id}`, body),
  deleteBanner: (id: number) => Request.delete(`banners/${id}`),
  uploadBannerImage: (body: any) => Request.post(`banner/upload-image`, body),
  patchBanner: (id: string, body: BannerDTO) =>
    Request.patch(`banner/${id}`, body),
  getSessionAmount: () => Request.get("session-amounts"),
  getSessionAmountById: (id: number) => Request.get(`session-amounts/${id}`),
  postSessionAmount: (body: SessionAmountDTO) =>
    Request.post(`session-amounts`, body),
  putSessionAmount: (id: number, body: SessionAmountDTO) =>
    Request.put(`session-amounts/${id}`, body),
  getTermAndConditions: () => Request.get("terms"),
  getTermAndConditionById: (id: number) => Request.get(`terms/${id}`),
  postTermAndConditions: (body: TermConditionsDTO) =>
    Request.post(`terms`, body),
  putTermAndCondition: (id: number, body: TermConditionsDTO) =>
    Request.put(`terms/${id}`, body),
  postOnBoarding: (body: OnBoardingDTO) => Request.post(`onboarding`, body),
  getOnBoarding: () => Request.get(`onboarding`),
  getOnBoardingById: (id: number) => Request.get(`onboarding/${id}`),
  putOnBoarding: (id: number, body: FormData) =>
    Request.put(`onboarding/${id} `, body),
  uploadOnBoardingImage: (body: any) =>
    Request.post(`onbording/upload-image`, body),
  getprivacyPolicy: () => Request.get("privacypolicy"),
  getprivacyPolicyById: (id: number) => Request.get(`privacypolicy/${id}`),
  postPrivacyPolicy: (body: any) => Request.post("privacypolicy", body),
  putPrivacyPolicy: (id: number, body: any) =>
    Request.put(`privacypolicy/${id}`, body),
  postQuestions: (body: DietaryChoiceDTO) => Request.post(`questions`, body),
  getQuestions: () => Request.get(`questions`),
  getQuestionById: (id: number) => Request.get(`/questions/${id}`),
  putQuestion: (id: number, body: DietaryChoiceDTO) =>
    Request.put(`questions/${id}`, body),
  getFaqs: () => Request.get("faqs"),
  postFaqs: (body: any) => Request.post("faqs", body),
  getFaqsById: (id: number | null) => Request.get(`faqs/${id}`),
  putFaqs: (id: number, body: any) => Request.put(`faqs/${id}`, body),
  postRetailer: (body: any) => Request.post("users/register-retailer", body),
  getRetailers: (queryParams: string) =>
    Request.get(`users?userRole=retailer&${queryParams}`),
  getRetailersById: (id: string) => Request.get(`/users?id=${id}`),
  getOrderByRetailerId: (queryParams: string) =>
    Request.get(`/orders?${queryParams}`),

  getPrintViewByOrderId: (orderId: string) =>
    Request.get(`/orders?orderId=${orderId}`),

  getRetailersBySearch: (queryParams: string) =>
    Request.get(`users?userRole=retailer&${queryParams}`),

  getUserByToken: () =>
    Request.get(`users/profile`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }),
  putUsers: (id: string, body: any) => Request.put(`users/${id}/status`, body),
  getDetailUserById: (id: string) => Request.get(`users?id=${id}`),
  getSessionDetailsByUserId: (userId: string, dishId: number) =>
    Request.get(`/sessions?userId=${userId}&dishId=${dishId}`),
  getSessionDetailsBySessionId: (sessionId: string) =>
    Request.get(`/sessions?sessionId=${sessionId}`),
  getSessionDetailsByHomeCookId: (homeCookId: string, status?: string) =>
    Request.get(
      `/sessions?homeCookId=${homeCookId}${status ? `&dishId=${status}` : ""}`
    ),
  getSessionDetailsForCook: (
    homeCookId: string,
    status?: string,
    createdAt?: string,
    dishName?: string,
    homeCookName?: string,
    limit?: number,
    offset?: number
  ) =>
    Request.get(
      `/sessions?homeCookId=${homeCookId}${status ? `&status=${status}` : ""}${
        dishName ? `&dishName=${dishName}` : ""
      }${createdAt ? `&createdAt=${createdAt}` : ""}${
        homeCookName ? `&homeCookName=${homeCookName}` : ""
      }${limit ? `&limit=${limit}` : ""}${offset ? `&offset=${offset}` : ""}`
    ),
  getSessionByUserById: (
    userId: string,
    status?: string,
    sessionId?: string,
    dishName?: string,
    createdAt?: string,
    homeCookName?: string,
    // dishId?: number,
    limit?: number,
    offset?: number
  ) =>
    Request.get(
      `/sessions?userId=${userId}${status ? `&status=${status}` : ""}${
        sessionId ? `&sessionId=${sessionId}` : ""
      }${dishName ? `&dishName=${dishName}` : ""}${
        createdAt ? `&createdAt=${createdAt}` : ""
      }${homeCookName ? `&homeCookName=${homeCookName}` : ""}
        ${limit ? `&limit=${limit}` : ""}
        ${
        offset ? `&offset=${offset}` : ""
      }`
    ),
  getOrderDetailsByUserId: (userId: string, orderId: string) =>
    Request.get(`/orders?userId=${userId}&orderId=${orderId}`),
  getAllOrders: (offset: number, limit: number) =>
    Request.get(`/orders/all`, {
      params: {
        offset,
        limit,
      },
    }),
  getOrderByUserById: (
    userId: string,
    status?: string,
    orderId?: string,
    createdAt?: string,
    retailerName?: string,
    limit?: number,
    offset?: number
  ) =>
    Request.get(
      `/orders?userId=${userId}${status ? `&status=${status}` : ""}${
        orderId ? `&orderId=${orderId}` : ""
      }${createdAt ? `&createdAt=${createdAt}` : ""}${
        retailerName ? `&retailerName=${retailerName}` : ""
      }${limit ? `&limit=${limit}` : ""}${offset ? `&offset=${offset}` : ""}`
    ),

  getDetailChiefById: (id: string) =>
    Request.get(`filtered/home-cooks?homeCookId=${id}`),
  getUser: (queryParams: string) =>
    Request.get(`users?userRole=user&${queryParams}`),
  getDishById: (
    homeCookId: string,
    dishName?: string,
    userDietaryChoice?: string,
    userState?: string,
    limit?: number,
    offset?: number
  ) =>
    Request.get(
      `filtered-dishes?homeCookId=${homeCookId}${
        dishName ? `&dishName=${dishName}` : ""
      }${userDietaryChoice ? `&userDietaryChoice=${userDietaryChoice}` : ""}${
        userState ? `&userState=${userState}` : ""
      }${limit ? `&limit=${limit}` : ""}${offset ? `&offset=${offset}` : ""}`
    ),
  // getDishById: (homeCookId: string, dishId?: number) =>
  //   Request.get(
  //     `filtered-dishes?homeCookId=${homeCookId}${
  //       dishId ? `&dishId=${dishId}` : ""
  //     }`
  //   ),
  getDishByIdForHomeCook: (dishId?: number) =>
    Request.get(`filtered-dishes?dishId=${dishId}`),
  getReviewById: (homeCookId: string) =>
    Request.get(`rating?homeCookId=${homeCookId}`),
  getReviewsForDish: (homeCookId: string, dishId: number) =>
    Request.get(`rating?homeCookId=${homeCookId}&dishId=${dishId}`),

  getSessionDetailsActivity: (offset: number, limit: number) =>
    Request.get(`sessions`, {
      params: {
        offset,
        limit,
      },
    }),

  getSessionActivivty: (
    sessionId?: string,
    createdAt?: string,
    homeCookName?: string,
    status?: string
  ) =>
    Request.get(
      `/sessions?${sessionId ? `sessionId=${sessionId}&` : ""}${
        createdAt ? `createdAt=${createdAt}&` : ""
      }${homeCookName ? `homeCookName=${homeCookName}&` : ""}${
        status ? `status=${status}` : ""
      }`
    ),
  getOrderActivity: (
    orderId?: string,
    createdAt?: string,
    retailerName?: string
  ) =>
    Request.get(
      `/orders?${orderId ? `&orderId=${orderId}` : ""}${
        createdAt ? `&createdAt=${createdAt}` : ""
      }${retailerName ? `&retailerName=${retailerName}` : ""}`
    ),
  promoteHomeCook: (homeCookId: string, body: any) =>
    Request.put(`users/${homeCookId}/upgrade`, body),
  updateAdmin: (body: any) => Request.put(`users/`, body),
  generatePdfForUserSession: (body: any) =>
    Request.post(`/manage/session/pdf`, body),
  generatePdfForDish: (body: any) => Request.post(`/manage/dish/pdf`, body),
  getUserStats: () =>
    Request.get("/dashboard/stats/user", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }),
  getUserGraphStats: (selectedPeriod: string) =>
    Request.get(`/dashboard/graph-stats/user?data=${selectedPeriod}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }),
  getNotifications: (ofsset: number, limit: number) =>
    Request.get(
      `/notifications/admin-notifications?offset=${ofsset}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    ),
};
